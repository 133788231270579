import moment from 'moment'
import 'jspdf-autotable'
import { jsPDF } from 'jspdf'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import { format } from '@vicimpa/rubles'
import { processNewChatMessageByRoute } from '@/composables/chat'
import {
  subscriptionExpiredNotify, systemNotify, newLeadNotify, patientProcessedAppointmentNotification,
  processIncomingPatientCallNotif, processUserOperationNotification, minimumSmsNotificationBalanceNotify,
} from '@/composables/notificationHelper'
import bus from '@/bus'
import appConstants from '@/constants/constants'
import { pdfMixins } from '@/pdf-mixins'
import { fullNameToInitials, textToLines } from '@/composables/pdfHelper'
import { getPatientDocumentInfo } from '@/composables/patientHelper'
import {
  actContractNumberHidden, actFillSignerDoctorName, actPatientLabel, documentIntegratorInfoWithLogo,
  documentLogoRightPosition, purchaseOrderAdministratorHidden, purchaseOrderExecutorLabel,
  purchaseOrderFillExecutorDoctorName,
} from '@/composables/documentSettingsHelper'
import { getPaymentDiscountAmountByTreatmentDiary } from '@/composables/priceCalculationHelper'
import {
  showCommonToast, showFailedOperationToast, showOnlineAppointmentToast, showSuccessOperationToast
} from '@/composables/toast'

export const mixins = {
  methods: {
    findIndexesWithoutCalculation(countIndex, maxIndex, divideDigit, compareTo) {
      let calculated = (compareTo / (divideDigit / countIndex)) / countIndex
      if (calculated > maxIndex) {
        calculated = calculated / 2
      }
      if (Number.isInteger(calculated)) {
        return Array.from({length: countIndex}, () => calculated)
      }
      return []
    },
    findDentalIndexesByCalculation(countIndex, maxIndex, divideDigit, compareTo) {
      let result = this.findIndexesWithoutCalculation(countIndex, maxIndex, divideDigit, compareTo)
      if (result.length > 0) {
        return result
      }

      for (let n = 0; n < maxIndex; n++) {
        for (let i = 1; i <= countIndex; i++) {
          result = Array.from({length: countIndex - i}, () => n)
          for (let j = 0; j < i; j++) {
            result.push(n + 1)
            if ((result.reduce((a, b) => a + b, 0) * (divideDigit / countIndex)).toFixed(1) == compareTo) {
              return result
            }
          }
        }
      }
      return Array.from({length: countIndex}, () => 0)
    },
    getOhiDescription (index) {
      if (index === 0) {
        return ''
      }
      if (index < 0.5) {
        return 'Хорошая'
      }
      if (index <= 1.5 && index >= 0.5) {
        return 'Удовлетворительная'
      }
      if (index <= 2.5 && index >= 1.5) {
        return 'Неудовлетворительная'
      }
      if (index > 2.5) {
        return 'Плохая'
      }
    },
    getKpiDescription (index) {
      if (index == 0) {
        return 'Отличный'
      }
      if (index <= 1 && index != 0) {
        return 'Риск к заболеванию'
      }
      if (index > 1 && index <= 2) {
        return 'Легкий'
      }
      if (index > 2 && index <= 3.5) {
        return 'Средний'
      }
      if (index > 5) {
        return 'Тяжелый'
      }
    },
    formatPrice(price, withCurrency, commaSeparator = false) {
      const defaultNumberOfDecimalPlaces = 2
      let numberOfDecimalPlaces = this.getNumberOfDecimalPlaces()
      let isRoundingDownEnabled = this.isRoundingDownEnabled()
      price = price ? parseFloat(price) : 0
      numberOfDecimalPlaces = numberOfDecimalPlaces || numberOfDecimalPlaces === 0
        ? numberOfDecimalPlaces : defaultNumberOfDecimalPlaces
      if (price && isRoundingDownEnabled) {
        price += 0.00000000001
        const parts = price.toString().split('.')
        if (parts.length === 2) {
          const fractionalPart = parts[1]
          if (fractionalPart.length > numberOfDecimalPlaces) {
            price = numberOfDecimalPlaces ? `${parts[0]}.${fractionalPart.slice(0, numberOfDecimalPlaces)}` : parts[0]
          } else {
            price = price ? parseFloat(price).toFixed(numberOfDecimalPlaces) : 0
          }
        } else {
          price = price ? parseFloat(price).toFixed(numberOfDecimalPlaces) : 0
        }
      } else {
        if (price) {
          let stringPrice = price.toString()
          const priceLength = stringPrice.length
          const index = priceLength - 1
          const lastNumber = parseInt(stringPrice[index])
          const splittedPrice = stringPrice.split('.')
          const fractionalPartLength = splittedPrice.length > 1 ? splittedPrice[1].length : 0
          if (fractionalPartLength > 1 && numberOfDecimalPlaces > defaultNumberOfDecimalPlaces && lastNumber === 5) {
            stringPrice = stringPrice.slice(0, index) + '6'
          }
          stringPrice = parseFloat(stringPrice)
          price = stringPrice ? stringPrice.toFixed(numberOfDecimalPlaces) : 0
        }
      }
      price = numberOfDecimalPlaces > defaultNumberOfDecimalPlaces ? parseFloat(price) : price
      if (commaSeparator) {
        const parts = price.split('.')
        price = parts.length > 1 ?
          `${parts[0]},${parts[1].length > 1 ? parts[1] : `0${parts[1]}` }` : `${parts[0]},00`
      }
      return withCurrency ? this.getPriceWithCurrency(price) : price.toString()
    },
    formatDate(date) {
      return moment(parseInt(date)).format('YYYY-MM-DD HH:mm')
    },
    formatDateToSeconds(date) {
      return moment(parseInt(date)).format('YYYY-MM-DD HH:mm:ss')
    },
    formatAsDate(date) {
      return moment(parseInt(date)).format('L')
    },
    formatDateAsDayMonthYear(date) {
      return moment(parseInt(date)).format('DD.MM.YYYY')
    },
    formatDateAsDayMonthYearTime(date) {
      return moment(parseInt(date)).format('DD.MM.YYYY HH:mm')
    },
    formatDateAsISO(date) {
      return moment(parseInt(date)).toISOString()
    },
    formatDateAsDayMonthString(date) {
      return moment(parseInt(date)).format('DD MMMM')
    },
    formatDateAsHoursMinutes(date) {
      return moment(parseInt(date)).format('HH:mm')
    },
    getPriceWithCurrency(price) {
      return price.toString() + ' руб.'
    },
    getPdfDocumentHeader(
      document, integrator, font, pdfMargin, isAdvisoryOpinion = false, isDentalCard = false
    ) {
      let currentYPosition = 10
      const fontSize = 8
      const rowHeight = 4
      const ds = isAdvisoryOpinion ? this.getDocumentSettings() : null
      const pdfWidth = document.internal.pageSize.width
      document.setFont(font)
      document.setFontSize(fontSize)

      if (integrator.logo_png) {
        const img = new Image()
        img.src = integrator.logo_png.url
        const height = 20
        const scaling_y = height / integrator.logo_png.height
        const width = integrator.logo_png.width * scaling_y
        document.addImage(
          img, 'png',
          !isDentalCard && !isAdvisoryOpinion && documentLogoRightPosition() ? pdfWidth - pdfMargin - width : pdfMargin,
          currentYPosition, width, height
        )
        currentYPosition += !isDentalCard && !isAdvisoryOpinion && documentIntegratorInfoWithLogo()
          ? 0 : height + rowHeight
      }

      if (
        integrator.name && (
          (ds && ds.hasOwnProperty('show_name') && ds.show_name) || !ds || (ds && !ds.hasOwnProperty('show_name'))
        )
      ) {
        const integratorName = this.formatText(integrator.name)
        document.text(integratorName, pdfMargin, currentYPosition)
        currentYPosition += rowHeight
      }

      if (
        integrator.license_number && (
          (ds && ds.hasOwnProperty('show_license_number') && ds.show_license_number)
          || !ds || (ds && !ds.hasOwnProperty('show_license_number'))
        )
      ) {
        document.text('Лиц. МЗ РБ: ' + integrator.license_number, pdfMargin, currentYPosition)
        currentYPosition += rowHeight
      }

      if (
        integrator.address
        && (
          (ds && ds.hasOwnProperty('show_address') && ds.show_address)
          || !ds || (ds && !ds.hasOwnProperty('show_address'))
        )
      ) {
        document.text('Адрес: ' + this.formatText(integrator.address), pdfMargin, currentYPosition)
        currentYPosition += rowHeight
      }

      if (
        integrator.phone && (
          (ds && ds.hasOwnProperty('show_phone') && ds.show_phone)
          || !ds || (ds && !ds.hasOwnProperty('show_phone'))
        )
      ) {
        const phone = integrator.phone[0] !== '+' && integrator.phone.slice(0, 3) === '375'
          ? '+' + integrator.phone : integrator.phone
        document.text('Тел.: ' + phone, pdfMargin, currentYPosition)
        currentYPosition += rowHeight
      }

      if (
        integrator.current_account && (
          (ds && ds.hasOwnProperty('show_current_account') && ds.show_current_account) || !ds
          || (ds && !ds.hasOwnProperty('show_current_account'))
        )
      ) {
        document.text('Расчетный счет: № ' + integrator.current_account, pdfMargin, currentYPosition)
        currentYPosition += rowHeight
      }

      if (
        integrator.bank && (
          (ds && ds.hasOwnProperty('show_bank') && ds.show_bank) || !ds
          || (ds && !ds.hasOwnProperty('show_bank'))
        )
      ) {
        document.text(integrator.bank, pdfMargin, currentYPosition)
        currentYPosition += rowHeight
      }

      let credentials = []
      if (
        integrator.bic && (
          (ds && ds.hasOwnProperty('show_bic') && ds.show_bic) || !ds || (ds && !ds.hasOwnProperty('show_bic'))
        )
      ) {
        credentials.push('БИК ' + integrator.bic)
      }

      if (
        integrator.unp && (
          (ds && ds.hasOwnProperty('show_unp') && ds.show_unp) || !ds || (ds && !ds.hasOwnProperty('show_unp'))
        )
      ) {
        credentials.push('УНП ' + integrator.unp)
      }

      if (credentials.length) {
        document.text(credentials.join(', '), pdfMargin, currentYPosition)
        currentYPosition += rowHeight
      }

      return document
    },
    getPdfDocumentHeaderCredentialsCount(integrator, isAdvisoryOpinion = false) {
      let counter = 0
      const credentials = [
        'name', 'phone', 'address', 'current_account', 'bank', 'bic', 'license_number', 'unp'
      ]
      const ds = isAdvisoryOpinion ? this.getDocumentSettings() : null
      for (let index = 0; index < credentials.length; index++) {
        if (
          integrator[credentials[index]] && (
            (ds && ds.hasOwnProperty('show_' + credentials[index]) && ds['show_' + credentials[index]])
            || !ds || (ds && !ds.hasOwnProperty('show_' + credentials[index]))
          )
        ) {
          ++counter
        }
      }
      return counter
    },
    getPositionAfterHeader(
      integrator, minYPosition, hasRightHeader, isAdvisoryOpinion = false, isDentalCard = false
    ) {
      const lineHeight = 4
      const headerCredentialsCount = mixins.methods.getPdfDocumentHeaderCredentialsCount(
        integrator, isAdvisoryOpinion
      )
      const imageHeight = 24
      const pdfMarginTop = 10

      let pdfCurrentPositionY = 0
      if (!integrator.logo_png) {
        pdfCurrentPositionY = minYPosition + ((headerCredentialsCount - 1) * lineHeight) - imageHeight
        pdfCurrentPositionY = pdfCurrentPositionY < minYPosition && hasRightHeader ? minYPosition : pdfCurrentPositionY
      } else if (headerCredentialsCount > 1) {
        pdfCurrentPositionY = minYPosition + ((headerCredentialsCount - 1) * lineHeight)
      } else {
        pdfCurrentPositionY = minYPosition
      }

      if (
        (!integrator.type || !integrator.type === appConstants.integrator.type.DENTAL_LABORATORY)
        && documentIntegratorInfoWithLogo() && !isDentalCard
      ) {
        pdfCurrentPositionY = pdfCurrentPositionY <= imageHeight + lineHeight + pdfMarginTop
          ? pdfMarginTop + imageHeight + lineHeight
          : pdfMarginTop + lineHeight + ((headerCredentialsCount - 1) * lineHeight)
      }

      return pdfCurrentPositionY
    },
    async createContractAboutProvisionOfPaidDentalCareForTreatmentDiary(treatmentDiary, patient) {
      const userData = getUserData()
      if (userData) {
        await store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            showCommonToast(
              'Ошибка формирования договора о предоставлении платной стоматологической помощи',
              'Пожалуйста, обратитесь в службу технической поддержки',
              'danger'
            )
            return false
          }
          const pdfFontNormal = 'OpenSans-Regular'
          const pdfFontBold = 'OpenSans-Bold'
          const pdfMargin = 8
          const pdfFontSize = 10
          const pdfWidth = 210
          let pdfRowHeight = 7
          const clinicName = integrator.name ? mixins.methods.formatText(integrator.name) : ''
          let pdfCurrentPositionY = 0
          let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})

          // header
          pdfFile = mixins.methods.getPdfDocumentHeader(pdfFile, integrator, pdfFontNormal, pdfMargin)
          pdfCurrentPositionY = mixins.methods.getPositionAfterHeader(integrator, 42, false)
          pdfFile.setFontSize(pdfFontSize)
          pdfFile.setFont(pdfFontBold)
          pdfFile.text(
            'Договор о предоставлении платной стоматологической помощи.',
            pdfWidth / 2,
            pdfCurrentPositionY,
            'center'
          )
          pdfCurrentPositionY += pdfRowHeight
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(moment(parseInt(treatmentDiary.date)).format('L'), pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * 2)

          let citizen = 'Гражданин '
          let citizenWidth = pdfFile.getTextWidth(citizen)
          pdfFile.text(citizen, pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontBold)
          pdfRowHeight = 5
          let patientName = patient.full_name ? patient.full_name : ''
          let patientNameWidth = pdfFile.getTextWidth(patientName + ', ')
          pdfFile.text(patientName + ', ', pdfMargin + citizenWidth, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(
            'именуемый в дальнейшем "Заказчик", с одной стороны и',
            pdfMargin + citizenWidth + patientNameWidth,
            pdfCurrentPositionY
          )

          pdfCurrentPositionY += pdfRowHeight
          pdfFile.setFont(pdfFontBold)
          let clinicWord = clinicName + " "
          let clinicWordWidth = pdfFile.getTextWidth(clinicWord)
          pdfFile.text(clinicWord, pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(
            'именуемое в дальнейшем "Исполнитель" с другой стороны, заключили',
            pdfMargin + clinicWordWidth,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('договор о нижеследующем:', pdfMargin, pdfCurrentPositionY)
          pdfCurrentPositionY += (pdfRowHeight * 2)

          pdfFile.text('1. ПРЕДМЕТ ДОГОВОРА.', pdfWidth / 2, pdfCurrentPositionY, 'center')
          pdfCurrentPositionY += (pdfRowHeight + 1)
          let lines = pdfFile.splitTextToSize(
            'Заказчик полностью доверяет, а Исполнитель принимает на себя предоставление услуг по лечению зубов и амбулаторной хирургии челюстнолицевой области, гарантирует качество услуг при выполнении следующих взаимных обязательств:',
            pdfWidth - (pdfMargin * 2)
          )
          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * lines.length)

          pdfFile.text('2. ОБЯЗАННОСТИ СТОРОН.', pdfWidth / 2, pdfCurrentPositionY, 'center')
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.setFont(pdfFontBold)
          pdfFile.text('Исполнитель обязан:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          pdfCurrentPositionY += pdfRowHeight

          lines = pdfFile.splitTextToSize(
            '2.1. Информировать Заказчика о состоянии его зубочелюстной системы и возможных методах лечения выявленных нарушений.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * lines.length)

          pdfFile.text(
            '2.2. Информировать Заказчика о сути предлагаемого лечения и возможных осложнениях.',
            pdfMargin,
            pdfCurrentPositionY
          )

          pdfCurrentPositionY += pdfRowHeight

          lines = pdfFile.splitTextToSize(
            '2.3. Исполнитель гарантирует выполнение операций и манипуляций в соответствии с современными стандартами качества лечения.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfCurrentPositionY += (pdfRowHeight * lines.length)

          pdfFile.text('2.4. Проводить регулярные осмотры в течение всего лечения.', pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text(
            '2.5. Согласовывать дату проведения операций, дальнейших осмотров и этапов лечения.',
            pdfMargin,
            pdfCurrentPositionY
          )

          pdfCurrentPositionY += (pdfRowHeight * 2)

          pdfFile.setFont(pdfFontBold)
          pdfFile.text('Заказчик обязан:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('2.6. Выполнять врачебные рекомендации.', pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += pdfRowHeight

          lines = pdfFile.splitTextToSize(
            '2.7. Посещать врача в назначенное время для проведения контрольных осмотров и продолжения лечения.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * lines.length)

          lines = pdfFile.splitTextToSize(
            '2.8. В случае возникновения осложнений или иных проблем, связанных с лечением, незамедлительно обратиться к Исполнителю, не прибегая к помощи других лечебных учреждений.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * lines.length)

          lines = pdfFile.splitTextToSize(
            '2.9. Предоставить достоверную информацию о состоянии здоровья, перенесенных заболеваниях и их хронических формах.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * (lines.length + 1))

          pdfFile.text('3. СТОИМОСТЬ РАБОТ И ПОРЯДОК РАСЧЕТОВ.', pdfWidth / 2, pdfCurrentPositionY, 'center')

          pdfCurrentPositionY += pdfRowHeight

          lines = pdfFile.splitTextToSize(
            '3.1. Заказчик производит оплату за лечение в зависимости от объема выполненных работ, изложенных в приложении 1.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * lines.length)

          lines = pdfFile.splitTextToSize(
            '3.2. Расчет производится через кассовый аппарат исполнителя или перечисление на расчетный счет Исполнителя согласно выставленного Исполнителем счета.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * (lines.length + 1))

          pdfFile.text('4. ОСОБЫЕ УСЛОВИЯ.', pdfWidth / 2, pdfCurrentPositionY, 'center')

          pdfCurrentPositionY += pdfRowHeight

          lines = pdfFile.splitTextToSize(
            '4.1. При угрожающих жизни и здоровью пациента состояния, изменение объема и соответственно стоимости медицинской помощи, производится по усмотрению Исполнителя, а в остальных  случаях - по согласованию сторон.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * lines.length)

          lines = pdfFile.splitTextToSize(
            '4.2. По вине Заказчика (не явка на осмотр, не соблюдение рекомендаций врача, гигиенических мероприятий и т.п.) - Исполнитель финансовой ответственности не несет.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * lines.length)


          lines = pdfFile.splitTextToSize(
            '4.3. Заказчик выражает полное согласие на связь, в том числе направление уведомлений, запросов и информации, предложений, акций которые касаются непосредственной деятельности Исполнителя посредством e-mail, мессенджеров Viber, Telegram, WhatsApp, почтовой связи и иных каналов связи.',
            pdfWidth - (pdfMargin * 2)
          )

          pdfFile.text(lines, pdfMargin, pdfCurrentPositionY)

          pdfCurrentPositionY += (pdfRowHeight * (lines.length + 1))

          let executor = 'Исполнитель '
          pdfFile.text(executor, pdfMargin, pdfCurrentPositionY)
          pdfFile.line(
            pdfMargin + pdfFile.getTextWidth(executor),
            pdfCurrentPositionY,
            pdfMargin + pdfFile.getTextWidth(executor) + 40,
            pdfCurrentPositionY
          )

          let customer = 'Заказчик '
          let marginFromCenter = 15
          pdfFile.text(customer, marginFromCenter + (pdfWidth / 2), pdfCurrentPositionY)
          pdfFile.line(
            marginFromCenter + (pdfWidth / 2) + pdfFile.getTextWidth(customer),
            pdfCurrentPositionY,
            pdfWidth - pdfMargin,
            pdfCurrentPositionY
          )

          pdfFile.save('Договор о предоставлении платной стоматологической помощи - ' + patient.full_name + '.pdf')
        })
      }
    },
    async createActOfServicesRenderedForTreatmentDiary(treatmentDiary, patient, discountPercent) {
      const userData = getUserData()
      if (userData) {
        await store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            showCommonToast(
              'Ошибка формирования акта оказанных услуг',
              'Пожалуйста, обратитесь в службу технической поддержки',
              'danger'
            )
            return false
          }
          const pdfFontNormal = 'OpenSans-Regular'
          const pdfFontBold = 'OpenSans-Bold'
          const pdfMargin = 8
          const pdfFontSize = 10
          const pdfRowHeight = 7
          const pdfWidth = 210
          const pdfHeight = 297
          const clinicName = integrator.name ? mixins.methods.formatText(integrator.name) : ''
          const priceCalcSystem = mixins.methods.getMaterialPriceCalcSystem()

          let pdfCurrentPositionY = 0
          let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})

          // header
          pdfFile = mixins.methods.getPdfDocumentHeader(pdfFile, integrator, pdfFontNormal, pdfMargin)
          pdfCurrentPositionY = mixins.methods.getPositionAfterHeader(integrator, 42, false)

          pdfFile.setFontSize(pdfFontSize)
          let patientName = patient.full_name

          pdfFile.setFont(pdfFontBold)
          mixins.methods.setActTitle(pdfFile, integrator, treatmentDiary, pdfWidth, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          if (integrator.city) {
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Место составления ' + integrator.city, pdfWidth - pdfMargin, pdfCurrentPositionY, 'right')
          }

          pdfCurrentPositionY += (pdfRowHeight * 2)

          let rightDataPosition = pdfWidth / 5
          rightDataPosition = treatmentDiary.nurse ? rightDataPosition + 2 : rightDataPosition
          pdfFile.setFont(pdfFontBold)
          pdfFile.text('Дата:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          let stringDate = moment(parseInt(treatmentDiary.date)).format('L')
          pdfFile.text(stringDate, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(
            rightDataPosition,
            pdfCurrentPositionY + 1,
            rightDataPosition + pdfFile.getTextWidth(stringDate),
            pdfCurrentPositionY + 1,
          )

          if (!actContractNumberHidden()) {
            pdfFile.setFont(pdfFontBold)
            let actText = 'Договор №:  '
            pdfFile.text(actText, pdfWidth / 2, pdfCurrentPositionY, 'center')
            pdfFile.setFont(pdfFontNormal)
            const contractNumber = mixins.methods.getActContractNumber(treatmentDiary, patient)
            const contractNumberWidth = pdfMixins.methods.getTextWidth(pdfFile, contractNumber)
            const actTextWidth = pdfMixins.methods.getTextWidth(pdfFile, actText)
            pdfFile.text(contractNumber, (pdfWidth / 2) + (actTextWidth / 2) + 1, pdfCurrentPositionY)
            pdfFile.line(
              (pdfWidth / 2) + (actTextWidth / 2),
              pdfCurrentPositionY + 1,
              (pdfWidth / 2) + ((actTextWidth / 2) + contractNumberWidth + 2),
              pdfCurrentPositionY + 1
            )
          }

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Заказчик:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(patientName, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Адрес:', pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(patient.address ? patient.address : '', rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(
            rightDataPosition,
            pdfCurrentPositionY + 1,
            pdfWidth - pdfMargin,
            pdfCurrentPositionY + 1
          )

          if (treatmentDiary.doctor) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О врача:', pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.doctor.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          if (treatmentDiary.lab_technician) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О лаборанта:', pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.lab_technician.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          if (treatmentDiary.nurse) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О мед. сестры:', pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.nurse.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          pdfCurrentPositionY += (pdfRowHeight * 2)

          pdfFile.setFont(pdfFontBold)
          if (treatmentDiary.type === 'DENTAL') {
            const teethNumbers = []
            const teethTitle = 'Зуб(ы): '
            const teethTitleWidth = pdfMixins.methods.getTextWidth(pdfFile, teethTitle)
            treatmentDiary.status_teeth.map(t => t.teeth ? teethNumbers.push(t.teeth.number) : null)
            pdfFile.text(teethTitle, pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(
              teethNumbers.length ? teethNumbers.join(',') : 'Не указаны',
              pdfMargin + teethTitleWidth,
              pdfCurrentPositionY
            )
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight

            if (!mixins.methods.isWarrantyHiddenInDocument()) {
              const warrantyTitle = 'Гарантия: '
              const warrantyTitleWidth = pdfMixins.methods.getTextWidth(pdfFile, warrantyTitle)
              const warrantyText = treatmentDiary.without_warranty
                ? 'Без гарантии' : (treatmentDiary.warranty ? `${treatmentDiary.warranty.toString()} мес.` : '')
              pdfFile.text(warrantyTitle, pdfMargin, pdfCurrentPositionY)
              pdfFile.setFont(pdfFontNormal)
              pdfFile.text(warrantyText, pdfMargin + warrantyTitleWidth, pdfCurrentPositionY)
              pdfFile.setFont(pdfFontBold)
              pdfCurrentPositionY += pdfRowHeight
            }
          }

          if (!mixins.methods.isDiagnosisHiddenInDocument()) {
            let label = 'Диагноз: '
            const diagnosisLabelWidth = pdfMixins.methods.getTextWidth(pdfFile, label)
            pdfFile.text(label, pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            const diagnosis = treatmentDiary && treatmentDiary.diagnosis_custom_name
              ? treatmentDiary.diagnosis_custom_name
              : (
                treatmentDiary.diagnoses.length
                  ? treatmentDiary.diagnoses.map(d => d.full_name).join(', ')
                  : 'Без диагноза'
              )
            if (pdfMixins.methods.getTextWidth(pdfFile, diagnosis) > pdfWidth - diagnosisLabelWidth - pdfMargin * 2) {
              const splittedText = pdfFile.splitTextToSize(
                diagnosis, pdfWidth - diagnosisLabelWidth - pdfMargin * 2
              )
              pdfFile.text(splittedText[0], pdfMargin + diagnosisLabelWidth, pdfCurrentPositionY)
              pdfCurrentPositionY += pdfRowHeight
              pdfFile.text(splittedText.slice(1), pdfMargin, pdfCurrentPositionY)
            } else {
              pdfFile.text(diagnosis, pdfMargin + diagnosisLabelWidth, pdfCurrentPositionY)
            }
          } else {
            pdfFile.setFont(pdfFontNormal)
            pdfCurrentPositionY -= pdfRowHeight
          }

          // Table services title
          const title = pdfMixins.methods.getServicesTableTitleForActOfServicesRendered(clinicName)
          const titleLength = title.length
          let splittedTitleItemsCount = 0
          pdfCurrentPositionY += pdfRowHeight
          if (titleLength + pdfMargin > pdfCurrentPositionY) {
            const splittedFirstPartStr = title.substring(
              0, pdfCurrentPositionY - 1 - pdfMargin
            ).split(' ')
            splittedFirstPartStr.splice(splittedFirstPartStr.length - 1, 1)
            const firstStr = splittedFirstPartStr.join(' ').trim()
            let newStr = title.substring(title.indexOf(firstStr) + firstStr.length, title.length).trim()
            pdfFile.text(firstStr, pdfMargin, pdfCurrentPositionY)
            const splittedConclusion = pdfFile.splitTextToSize(newStr, pdfWidth - (pdfMargin * 2))
            splittedTitleItemsCount = splittedConclusion.length
            pdfFile.setLineHeightFactor(2)
            pdfFile.text(splittedConclusion, pdfMargin, pdfCurrentPositionY + 8)
            pdfCurrentPositionY += (pdfRowHeight * splittedTitleItemsCount)
            pdfFile.setLineHeightFactor(1)
          } else {
            pdfFile.text(title, pdfMargin, pdfCurrentPositionY)
          }

          let rows = [
            [
              {content: 'Номер', styles: {halign: 'center'}},
              {content: 'Наименование', styles: {halign: 'center'}},
              {content: 'Кол', styles: {halign: 'center'}},
              {content: 'Цена', styles: {halign: 'center'}},
              {content: 'Сумма', styles: {halign: 'center'}}
            ]
          ]

          let totalPrice = 0
          let totalMaterialsPrice = 0
          let totalMaterialsNds10Price = 0
          let totalMaterialsNds20Price = 0
          let totalMaterialsPriceWithNds = 0
          const showNdsInfoInDocument = !!mixins.methods.showNdsInfoInDocument()
          treatmentDiary.dental_services_history.forEach(s => {
            rows.push([
              {content: s.number, styles: {halign: 'center'}},
              {content: s.name},
              {content: s.count, styles: {halign: 'center'}},
              {
                content: mixins.methods.formatPrice(s.total_price, true),
                styles: {halign: 'center'}
              },
              {
                content: mixins.methods.formatPrice(s.total_price * s.count, true),
                styles: {halign: 'center'}
              }
            ])
            totalPrice += parseFloat(mixins.methods.formatPrice(s.total_price * s.count, false))
            if (showNdsInfoInDocument) {
              const materials = s.materials && s.materials.length ? s.materials : []
              materials.map(m => {
                const nds = m.nds_value
                const pricePerUnit = m.price
                const totalPrice = pricePerUnit * s.count * m.part_rate
                let ndsPrice
                if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
                  ndsPrice = (m.price_per_unit - m.price_per_unit_without_nds) * s.count * m.part_rate
                } else {
                  ndsPrice = nds ? (totalPrice / 100) * nds : null
                }
                const totalPriceWithNds = ndsPrice ? totalPrice + ndsPrice : totalPrice
                if (nds === 10) {
                  totalMaterialsNds10Price += ndsPrice
                }
                if (nds === 20) {
                  totalMaterialsNds20Price += ndsPrice
                }
                totalMaterialsPrice += totalPrice
                totalMaterialsPriceWithNds += totalPriceWithNds
              })
              totalMaterialsNds10Price = parseFloat(
                mixins.methods.formatPrice(totalMaterialsNds10Price, false)
              )
              totalMaterialsNds20Price = parseFloat(
                mixins.methods.formatPrice(totalMaterialsNds20Price, false)
              )
              totalMaterialsPriceWithNds = parseFloat(
                mixins.methods.formatPrice(totalMaterialsPriceWithNds, false)
              )
            }
          })
          totalPrice = parseFloat(mixins.methods.formatPrice(totalPrice, false))
          const discountAmount = treatmentDiary.dental_services_history.length
            ? getPaymentDiscountAmountByTreatmentDiary(treatmentDiary, discountPercent)
            : (discountPercent / 100) * totalPrice
          let salePrice = discountPercent ? (totalPrice - discountAmount) : totalPrice
          salePrice = salePrice < 0 ? 0 : salePrice
          rows.push([
            {content: 'Итого', styles: {halign: 'left'}, colSpan: 4},
            {content: mixins.methods.formatPrice(totalPrice, true), styles: { halign: 'center' }}
          ])
          pdfFile.autoTable({
            theme: 'grid',
            startY: pdfCurrentPositionY + pdfRowHeight,
            body: rows,
            bodyStyles: {
              cellPadding: 1,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0]
            },
            styles: {
              font: pdfFontNormal,
              fontSize: pdfFontSize,
            },
            didParseCell: hook => {
              if (hook.row.index === 0) {
                hook.cell.styles.font = pdfFontBold
              }
            },
            didDrawPage: hook => {
              pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
            }
          })

          if (pdfHeight - (pdfRowHeight * 7) < pdfCurrentPositionY) {
            pdfFile.addPage()
            pdfCurrentPositionY = pdfRowHeight * 2
          }

          if (showNdsInfoInDocument && totalMaterialsPrice) {
            pdfFile.text(
              `Итого сумма без НДС: ${mixins.methods.formatPrice(totalMaterialsPrice, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }

            pdfFile.text(
              `в т.ч. сумма НДС 10%: ${mixins.methods.formatPrice(totalMaterialsNds10Price, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }

            pdfFile.text(
              `в т.ч. сумма НДС 20%: ${mixins.methods.formatPrice(totalMaterialsNds20Price, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }

            pdfFile.text(
              `Итого сумма с учетом НДС: ${mixins.methods.formatPrice(totalMaterialsPriceWithNds, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }
          }

          pdfFile.text(
            'Вышеперечисленные услуги выполнены полностью. Заказчик по объему, качеству и срокам оказания услуг',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text(
            'претензий не имеет. Настоящий акт является основанием к оплате оказанных услуг.',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += (pdfRowHeight * 2)

          pdfFile.setFont(pdfFontBold)
          if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
            pdfFile.addPage()
            pdfCurrentPositionY = 10
          }
          pdfFile.text('ИТОГО', pdfMargin, pdfCurrentPositionY)
          pdfCurrentPositionY += pdfRowHeight

          let totalText = 'Начислено:   '
          pdfFile.text(totalText, pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(
            mixins.methods.formatPrice(totalPrice, true),
            pdfMargin + pdfFile.getTextWidth(totalText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)

          let discountText = 'Скидка %:   '
          pdfFile.text(discountText, (pdfWidth / 4) + 4, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            discountPercent ? discountPercent.toString() : '0',
            (pdfWidth / 4) + 4 + pdfFile.getTextWidth(discountText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)
          let payText = 'К оплате:   '
          pdfFile.text(payText, pdfWidth / 2, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            (salePrice > 0 && totalPrice) || discountPercent === 100
              ? mixins.methods.formatPrice(salePrice, true)
              : mixins.methods.formatPrice(totalPrice, true),
            (pdfWidth / 2) + pdfFile.getTextWidth(payText),
            pdfCurrentPositionY
          )

          pdfCurrentPositionY += (pdfRowHeight * 2)
          pdfFile.setFont(pdfFontBold)
          pdfFile.setDrawColor(0,0,0,1)
          let cashierText = mixins.methods.getSignerLabelForAct()
          pdfFile.text(cashierText, pdfMargin, pdfCurrentPositionY)
          const offset = pdfMargin + pdfFile.getTextWidth(cashierText)
          if (actFillSignerDoctorName() && treatmentDiary.doctor) {
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.doctor.full_name_initials, offset, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontBold)
          }
          pdfFile.line(offset, pdfCurrentPositionY + 1, offset + 40, pdfCurrentPositionY + 1)

          let patientText = `${actPatientLabel()}: `
          pdfFile.text(patientText, pdfWidth / 2, pdfCurrentPositionY)

          pdfFile.line(
            (pdfWidth / 2) + pdfFile.getTextWidth(patientText),
            pdfCurrentPositionY + 1,
            pdfWidth - pdfMargin,
            pdfCurrentPositionY + 1,
          )

          pdfFile.save(mixins.methods.getActTitle(treatmentDiary) + ' - ' + patient.full_name + '.pdf')
        })
      }
    },
    async createShortActOfServicesRenderedForTreatmentDiary(treatmentDiary, patient, discountPercent) {
      const userData = getUserData()
      if (userData) {
        await store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            showCommonToast(
              'Ошибка формирования акта оказанных услуг',
              'Пожалуйста, обратитесь в службу технической поддержки',
              'danger'
            )
            return false
          }
          const pdfFontNormal = 'OpenSans-Regular'
          const pdfFontBold = 'OpenSans-Bold'
          const pdfMargin = 8
          const pdfFontSize = 10
          const pdfRowHeight = 7
          const pdfWidth = 210
          const clinicName = integrator.name ? this.formatText(integrator.name) : ''

          let pdfCurrentPositionY = 0
          let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})

          // header
          pdfFile = mixins.methods.getPdfDocumentHeader(pdfFile, integrator, pdfFontNormal, pdfMargin)
          pdfCurrentPositionY = mixins.methods.getPositionAfterHeader(integrator, 42, false)

          pdfFile.setFontSize(pdfFontSize)
          let patientName = patient.full_name

          pdfFile.setFont(pdfFontBold)
          mixins.methods.setActTitle(pdfFile, integrator, treatmentDiary, pdfWidth, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          if (integrator.city) {
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Место составления ' + integrator.city, pdfWidth - pdfMargin, pdfCurrentPositionY, 'right')
            pdfCurrentPositionY += pdfRowHeight
          } else {
            pdfCurrentPositionY += (pdfRowHeight * 2)
          }

          let rightDataPosition = pdfWidth / 5
          rightDataPosition = treatmentDiary.nurse ? rightDataPosition + 2 : rightDataPosition
          pdfFile.setFont(pdfFontBold)
          pdfFile.text('Дата:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          let stringDate = moment(parseInt(treatmentDiary.date)).format('L')
          pdfFile.text(stringDate, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(
            rightDataPosition,
            pdfCurrentPositionY + 1,
            rightDataPosition + pdfFile.getTextWidth(stringDate),
            pdfCurrentPositionY + 1,
          )

          if (!actContractNumberHidden()) {
            pdfFile.setFont(pdfFontBold)
            let actText = 'Договор №:  '
            pdfFile.text(actText, pdfWidth / 2, pdfCurrentPositionY, 'center')
            pdfFile.setFont(pdfFontNormal)
            const contractNumber = mixins.methods.getActContractNumber(treatmentDiary, patient)
            const contractNumberWidth = pdfMixins.methods.getTextWidth(pdfFile, contractNumber)
            const actTextWidth = pdfMixins.methods.getTextWidth(pdfFile, actText)
            pdfFile.text(contractNumber, (pdfWidth / 2) + (actTextWidth / 2) + 1, pdfCurrentPositionY)
            pdfFile.line(
              (pdfWidth / 2) + (actTextWidth / 2),
              pdfCurrentPositionY + 1,
              (pdfWidth / 2) + ((actTextWidth / 2) + contractNumberWidth + 2),
              pdfCurrentPositionY + 1
            )
          }

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Заказчик:', pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(patientName, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Адрес:', pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(patient.address ? patient.address : '', rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

          if (treatmentDiary.doctor) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О врача:', pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.doctor.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          if (treatmentDiary.lab_technician) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О лаборанта:', pdfMargin, pdfCurrentPositionY)

            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.lab_technician.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          if (treatmentDiary.nurse) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О мед. сестры:', pdfMargin, pdfCurrentPositionY)

            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.nurse.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.setFont(pdfFontBold)
          if (treatmentDiary.type === 'DENTAL') {
            const teethNumbers = []
            const teethTitle = 'Зуб(ы): '
            const teethTitleWidth = pdfMixins.methods.getTextWidth(pdfFile, teethTitle)
            treatmentDiary.status_teeth.map(t => t.teeth ? teethNumbers.push(t.teeth.number) : null)
            pdfFile.text(teethTitle, pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(
              teethNumbers.length ? teethNumbers.join(',') : 'Не указаны',
              pdfMargin + teethTitleWidth,
              pdfCurrentPositionY
            )
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight

            if (!mixins.methods.isWarrantyHiddenInDocument()) {
              const warrantyTitle = 'Гарантия: '
              const warrantyTitleWidth = pdfMixins.methods.getTextWidth(pdfFile, warrantyTitle)
              const warrantyText = treatmentDiary.without_warranty
                ? 'Без гарантии' : (treatmentDiary.warranty ? `${treatmentDiary.warranty.toString()} мес.` : '')
              pdfFile.text(warrantyTitle, pdfMargin, pdfCurrentPositionY)
              pdfFile.setFont(pdfFontNormal)
              pdfFile.text(warrantyText, pdfMargin + warrantyTitleWidth, pdfCurrentPositionY)
              pdfFile.setFont(pdfFontBold)
              pdfCurrentPositionY += pdfRowHeight
            }
          }

          if (!mixins.methods.isDiagnosisHiddenInDocument()) {
            let label = 'Диагноз: '
            const diagnosisLabelWidth = pdfMixins.methods.getTextWidth(pdfFile, label)
            pdfFile.text(label, pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            const diagnosis = treatmentDiary && treatmentDiary.diagnosis_custom_name
              ? treatmentDiary.diagnosis_custom_name
              : (
                treatmentDiary.diagnoses.length
                  ? treatmentDiary.diagnoses.map(d => d.full_name).join(', ')
                  : 'Без диагноза'
              )
            if (pdfMixins.methods.getTextWidth(pdfFile, diagnosis) > pdfWidth - diagnosisLabelWidth - pdfMargin * 2) {
              const splittedText = pdfFile.splitTextToSize(
                diagnosis, pdfWidth - diagnosisLabelWidth - pdfMargin * 2
              )
              pdfFile.text(splittedText[0], pdfMargin + diagnosisLabelWidth, pdfCurrentPositionY)
              pdfCurrentPositionY += pdfRowHeight
              pdfFile.text(splittedText.slice(1), pdfMargin, pdfCurrentPositionY)
            } else {
              pdfFile.text(diagnosis, pdfMargin + diagnosisLabelWidth, pdfCurrentPositionY)
            }
          } else {
            pdfFile.setFont(pdfFontNormal)
            pdfCurrentPositionY -= pdfRowHeight
          }

          // Table services title
          const title = pdfMixins.methods.getServicesTableTitleForActOfServicesRendered(clinicName)
          pdfCurrentPositionY += pdfRowHeight
          pdfCurrentPositionY = textToLines(
            title, null, pdfFile, pdfMargin, pdfCurrentPositionY, pdfFontNormal, true,
            pdfFile.internal.scaleFactor, false
          )
          pdfFile.setLineHeightFactor(1)
          pdfCurrentPositionY -= 4

          let rows = [[
            {content: 'Услуги', styles: {halign: 'center'}},
            {content: 'Сумма', styles: {halign: 'center'}}
          ]]

          let servicesNumbers = []
          let totalPrice = treatmentDiary.dental_services_history.reduce((val, s) => {
            if (!servicesNumbers.includes(s.number)) {
              servicesNumbers.push(s.number)
            }
            return val + parseFloat(this.formatPrice(s.total_price * s.count, false))
          }, 0)
          rows.push([
            {content: servicesNumbers.sort().join(', ')},
            {content: this.formatPrice(totalPrice, true), styles: {halign: 'center', valign: 'middle'}}
          ])
          totalPrice = parseFloat(mixins.methods.formatPrice(totalPrice, false))
          const discountAmount = treatmentDiary.dental_services_history.length
            ? getPaymentDiscountAmountByTreatmentDiary(treatmentDiary, discountPercent)
            : (discountPercent / 100) * totalPrice
          let salePrice = discountPercent ? (totalPrice - discountAmount) : totalPrice
          salePrice = salePrice < 0 ? 0 : salePrice
          pdfFile.autoTable({
            theme: 'grid',
            startY: pdfCurrentPositionY,
            body: rows,
            margin: {top: 0, right: pdfMargin, bottom: 0, left: pdfMargin},
            bodyStyles: {
              cellPadding: 1,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0]
            },
            styles: {
              font: pdfFontNormal,
              fontSize: pdfFontSize,
            },
            didParseCell: hook => {
              if (hook.row.index === 0) {
                hook.cell.styles.font = pdfFontBold
              }
            },
            didDrawPage: hook => {
              pdfCurrentPositionY = hook.cursor.y
            }
          })

          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text(
            'Вышеперечисленные услуги выполнены полностью. Заказчик по объему, качеству и срокам оказания услуг',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += pdfRowHeight - 2
          pdfFile.text(
            'претензий не имеет. Настоящий акт является основанием к оплате оказанных услуг.',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.setFont(pdfFontBold)
          pdfFile.text('ИТОГО', pdfMargin, pdfCurrentPositionY)
          pdfCurrentPositionY += pdfRowHeight

          let totalText = 'Начислено:   '
          pdfFile.text(totalText, pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(
            this.formatPrice(totalPrice, true),
            pdfMargin + pdfFile.getTextWidth(totalText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)

          let discountText = 'Скидка %:   '
          pdfFile.text(discountText, (pdfWidth / 4) + 4, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            discountPercent ? discountPercent.toString() : '0',
            (pdfWidth / 4) + 4 + pdfFile.getTextWidth(discountText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)
          let payText = 'К оплате:   '
          pdfFile.text(payText, pdfWidth / 2, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            (salePrice > 0 && totalPrice) || discountPercent === 100
              ? this.formatPrice(salePrice, true)
              : this.formatPrice(totalPrice, true),
            (pdfWidth / 2) + pdfFile.getTextWidth(payText),
            pdfCurrentPositionY
          )

          pdfCurrentPositionY += pdfRowHeight
          pdfFile.setFont(pdfFontBold)
          pdfFile.setDrawColor(0,0,0,1)
          let cashierText = mixins.methods.getSignerLabelForAct()
          pdfFile.text(cashierText, pdfMargin, pdfCurrentPositionY)
          const offset = pdfMargin + pdfFile.getTextWidth(cashierText)
          if (actFillSignerDoctorName() && treatmentDiary.doctor) {
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.doctor.full_name_initials, offset, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontBold)
          }
          pdfFile.line(offset, pdfCurrentPositionY + 1, pdfWidth / 2 - 10, pdfCurrentPositionY + 1)

          let patientText = `${actPatientLabel()}: `
          pdfFile.text(patientText, pdfWidth / 2, pdfCurrentPositionY)

          pdfFile.line(
            (pdfWidth / 2) + pdfFile.getTextWidth(patientText),
            pdfCurrentPositionY + 1,
            pdfWidth - pdfMargin,
            pdfCurrentPositionY + 1,
          )

          pdfFile.save(
            this.getActTitle(treatmentDiary) + ' (сокращенный формат) - ' + patient.full_name + '.pdf'
          )
        })
      }
    },
    async createActOfServicesRenderedWithMaterialsForTreatmentDiary(treatmentDiary, patient, discountPercent) {
      const userData = getUserData()
      if (userData) {
        store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            showCommonToast(
              'Ошибка формирования акта оказанных услуг',
              'Пожалуйста, обратитесь в службу технической поддержки',
              'danger'
            )
            return false
          }
          const priceCalcSystem = mixins.methods.getMaterialPriceCalcSystem()
          let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})
          const pdfFontNormal = 'OpenSans-Regular'
          const pdfFontBold = 'OpenSans-Bold'
          const pdfMargin = 8
          const pdfFontSize = 10
          const pdfRowHeight = 7
          const pdfWidth = 210
          const pdfHeight = pdfFile.internal.pageSize.height
          const clinicName = integrator.name ? mixins.methods.formatText(integrator.name) : ''

          let pdfCurrentPositionY = 0

          // header
          pdfFile = mixins.methods.getPdfDocumentHeader(pdfFile, integrator, pdfFontNormal, pdfMargin)
          pdfCurrentPositionY = mixins.methods.getPositionAfterHeader(integrator, 42, false)

          pdfFile.setFontSize(pdfFontSize)
          let patientName = patient.full_name

          pdfFile.setFont(pdfFontBold)
          mixins.methods.setActTitle(pdfFile, integrator, treatmentDiary, pdfWidth, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          if (integrator.city) {
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Место составления ' + integrator.city, pdfWidth - pdfMargin, pdfCurrentPositionY, 'right')
          }

          pdfCurrentPositionY += (pdfRowHeight * 2)

          let rightDataPosition = pdfWidth / 5
          rightDataPosition = treatmentDiary.nurse ? rightDataPosition + 2 : rightDataPosition
          pdfFile.setFont(pdfFontBold)
          pdfFile.text('Дата:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          let stringDate = moment(parseInt(treatmentDiary.date)).format('L')
          pdfFile.text(stringDate, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(
            rightDataPosition,
            pdfCurrentPositionY + 1,
            rightDataPosition + pdfFile.getTextWidth(stringDate),
            pdfCurrentPositionY + 1,
          )

          if (!actContractNumberHidden()) {
            pdfFile.setFont(pdfFontBold)
            let actText = 'Договор №:  '
            pdfFile.text(actText, pdfWidth / 2, pdfCurrentPositionY, 'center')
            pdfFile.setFont(pdfFontNormal)
            const contractNumber = mixins.methods.getActContractNumber(treatmentDiary, patient)
            const contractNumberWidth = pdfMixins.methods.getTextWidth(pdfFile, contractNumber)
            const actTextWidth = pdfMixins.methods.getTextWidth(pdfFile, actText)
            pdfFile.text(contractNumber, (pdfWidth / 2) + (actTextWidth / 2) + 1, pdfCurrentPositionY)
            pdfFile.line(
              (pdfWidth / 2) + (actTextWidth / 2),
              pdfCurrentPositionY + 1,
              (pdfWidth / 2) + ((actTextWidth / 2) + contractNumberWidth + 2),
              pdfCurrentPositionY + 1
            )
          }

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Заказчик:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(patientName, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Адрес:', pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(patient.address ? patient.address : '', rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(
            rightDataPosition,
            pdfCurrentPositionY + 1,
            pdfWidth - pdfMargin,
            pdfCurrentPositionY + 1
          )

          if (treatmentDiary.doctor) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О врача:', pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.doctor.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          if (treatmentDiary.lab_technician) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О лаборанта:', pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.lab_technician.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          if (treatmentDiary.nurse) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О мед. сестры:', pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.nurse.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          pdfCurrentPositionY += (pdfRowHeight * 2)

          pdfFile.setFont(pdfFontBold)
          if (treatmentDiary.type === 'DENTAL') {
            const teethNumbers = []
            const teethTitle = 'Зуб(ы): '
            const teethTitleWidth = pdfMixins.methods.getTextWidth(pdfFile, teethTitle)
            treatmentDiary.status_teeth.map(t => t.teeth ? teethNumbers.push(t.teeth.number) : null)
            pdfFile.text(teethTitle, pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(
              teethNumbers.length ? teethNumbers.join(',') : 'Не указаны',
              pdfMargin + teethTitleWidth,
              pdfCurrentPositionY
            )
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight

            if (!mixins.methods.isWarrantyHiddenInDocument()) {
              const warrantyTitle = 'Гарантия: '
              const warrantyTitleWidth = pdfMixins.methods.getTextWidth(pdfFile, warrantyTitle)
              const warrantyText = treatmentDiary.without_warranty
                ? 'Без гарантии' : (treatmentDiary.warranty ? `${treatmentDiary.warranty.toString()} мес.` : '')
              pdfFile.text(warrantyTitle, pdfMargin, pdfCurrentPositionY)
              pdfFile.setFont(pdfFontNormal)
              pdfFile.text(warrantyText, pdfMargin + warrantyTitleWidth, pdfCurrentPositionY)
              pdfFile.setFont(pdfFontBold)
              pdfCurrentPositionY += pdfRowHeight
            }
          }

          if (!mixins.methods.isDiagnosisHiddenInDocument()) {
            let label = 'Диагноз: '
            const diagnosisLabelWidth = pdfMixins.methods.getTextWidth(pdfFile, label)
            pdfFile.text(label, pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            const diagnosis = treatmentDiary && treatmentDiary.diagnosis_custom_name
              ? treatmentDiary.diagnosis_custom_name
              : (
                treatmentDiary.diagnoses.length
                  ? treatmentDiary.diagnoses.map(d => d.full_name).join(', ')
                  : 'Без диагноза'
              )
            if (pdfMixins.methods.getTextWidth(pdfFile, diagnosis) > pdfWidth - diagnosisLabelWidth - pdfMargin * 2) {
              const splittedText = pdfFile.splitTextToSize(
                diagnosis, pdfWidth - diagnosisLabelWidth - pdfMargin * 2
              )
              pdfFile.text(splittedText[0], pdfMargin + diagnosisLabelWidth, pdfCurrentPositionY)
              pdfCurrentPositionY += pdfRowHeight
              pdfFile.text(splittedText.slice(1), pdfMargin, pdfCurrentPositionY)
            } else {
              pdfFile.text(diagnosis, pdfMargin + diagnosisLabelWidth, pdfCurrentPositionY)
            }
          } else {
            pdfFile.setFont(pdfFontNormal)
            pdfCurrentPositionY -= pdfRowHeight
          }

          // Table services title
          const title = pdfMixins.methods.getServicesTableTitleForActOfServicesRendered(clinicName)
          const titleLength = title.length
          let splittedTitleItemsCount = 0
          pdfCurrentPositionY += pdfRowHeight
          if (titleLength + pdfMargin > pdfCurrentPositionY) {
            const splittedFirstPartStr = title.substring(
              0, pdfCurrentPositionY - 1 - pdfMargin
            ).split(' ')
            splittedFirstPartStr.splice(splittedFirstPartStr.length - 1, 1)
            const firstStr = splittedFirstPartStr.join(' ').trim()
            let newStr = title.substring(title.indexOf(firstStr) + firstStr.length, title.length).trim()
            pdfFile.text(firstStr, pdfMargin, pdfCurrentPositionY)
            const splittedConclusion = pdfFile.splitTextToSize(newStr, pdfWidth - (pdfMargin * 2))
            splittedTitleItemsCount = splittedConclusion.length
            pdfFile.setLineHeightFactor(2)
            pdfFile.text(splittedConclusion, pdfMargin, pdfCurrentPositionY + 8)
            pdfCurrentPositionY += (pdfRowHeight * splittedTitleItemsCount)
            pdfFile.setLineHeightFactor(1)
          } else {
            pdfFile.text(title, pdfMargin, pdfCurrentPositionY)
          }

          let rows = [
            [
              {content: 'Номер', styles: {halign: 'center'}},
              {content: 'Наименование', styles: {halign: 'center'}},
              {content: 'Кол', styles: {halign: 'center'}},
              {content: 'Цена', styles: {halign: 'center'}},
              {content: 'Сумма', styles: {halign: 'center'}}
            ]
          ]

          let totalMaterialsPrice = 0
          let totalMaterialsNds10Price = 0
          let totalMaterialsNds20Price = 0
          let totalMaterialsPriceWithNds = 0
          const materials = {}
          treatmentDiary.dental_services_history.forEach(service => {
            service.materials.map(m => {
              const totalPrice = m.price * service.count * m.part_rate
              let ndsPrice
              if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
                ndsPrice = (m.price_per_unit - m.price_per_unit_without_nds) * service.count * m.part_rate
              } else {
                ndsPrice = m.nds_value ? (totalPrice / 100) * m.nds_value : null
              }
              const totalPriceWithNds = ndsPrice ? totalPrice + ndsPrice : totalPrice

              if (materials[m.id]) {
                materials[m.id].count += service.count * m.part_rate
              } else {
                materials[m.id] = {
                  name: m.material_name,
                  sku: m.sku,
                  count: service.count * m.part_rate,
                  price: m.price,
                  price_per_unit: m.price_per_unit,
                  price_per_unit_without_nds: m.price_per_unit_without_nds,
                  nds: m.nds_value,
                  unitType: m.unit_type,
                }
              }

              if (m.nds_value === 10) {
                totalMaterialsNds10Price += ndsPrice
              }
              if (m.nds_value === 20) {
                totalMaterialsNds20Price += ndsPrice
              }
              totalMaterialsPrice += totalPrice
              totalMaterialsPriceWithNds += totalPriceWithNds
            })
            totalMaterialsNds10Price = parseFloat(
              mixins.methods.formatPrice(totalMaterialsNds10Price, false)
            )
            totalMaterialsNds20Price = parseFloat(
              mixins.methods.formatPrice(totalMaterialsNds20Price, false)
            )
            totalMaterialsPriceWithNds = parseFloat(
              mixins.methods.formatPrice(totalMaterialsPriceWithNds, false)
            )
            rows.push([
              {content: service.number, styles: {halign: 'center'}},
              {content: service.name},
              {content: service.count, styles: {halign: 'center'}},
              {
                content: mixins.methods.formatPrice(service.total_price, true),
                styles: {halign: 'center'}
              },
              {
                content: mixins.methods.formatPrice(
                  service.total_price * parseFloat(service.count), true
                ),
                styles: {halign: 'center'}
              }
            ])
          })
          let totalPrice = treatmentDiary.dental_services_history.reduce(
            (totalPrice, service) =>
              totalPrice + parseFloat(
                mixins.methods.formatPrice(service.total_price * service.count, false)
              ), 0
          )
          totalPrice = parseFloat(mixins.methods.formatPrice(totalPrice, false))
          const discountAmount = treatmentDiary.dental_services_history.length
            ? getPaymentDiscountAmountByTreatmentDiary(treatmentDiary, discountPercent)
            : (discountPercent / 100) * totalPrice
          let salePrice = discountPercent ? (totalPrice - discountAmount) : totalPrice
          salePrice = salePrice < 0 ? 0 : salePrice
          rows.push([
            {content: 'Итого', styles: {halign: 'left'}, colSpan: 4},
            {content: mixins.methods.formatPrice(totalPrice, true), styles: { halign: 'center' }}
          ])
          pdfFile.autoTable({
            theme: 'grid',
            startY: pdfCurrentPositionY + pdfRowHeight,
            body: rows,
            bodyStyles: {
              cellPadding: 1,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0]
            },
            styles: {
              font: pdfFontNormal,
              fontSize: pdfFontSize,
            },
            didParseCell: hook => {
              if (hook.row.index === 0) {
                hook.cell.styles.font = pdfFontBold
              }
            },
            didDrawPage: hook => {
              pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
            }
          })

          if (Object.keys(materials).length) {
            pdfFile.text('Использованные материалы:', pdfMargin, pdfCurrentPositionY)
            rows = [
              [
                {content: 'Артикул', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Наименование', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Стоимость без НДС', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Количество', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Ед. измерения', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Итого без НДС', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Ставка НДС', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Сумма НДС', styles: {halign: 'center', valign: 'middle'}},
                {content: 'Итого с НДС', styles: {halign: 'center', valign: 'middle'}}
              ]
            ]

            Object.keys(materials).map(key => {
              const material = materials[key]
              const nds = material.nds ? material.nds : null
              const totalPrice = material.price * material.count
              let ndsPrice
              if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
                ndsPrice = (material.price_per_unit - material.price_per_unit_without_nds) * material.count
              } else {
                ndsPrice = nds ? (totalPrice / 100) * nds : null
              }
              const totalPriceWithNds = ndsPrice ? totalPrice + ndsPrice : totalPrice
              let row = [
                {content: material.sku, styles: {halign: 'center', valign: 'middle'}},
                {content: material.name, styles: {halign: 'center', valign: 'middle'}},
                {
                  content: mixins.methods.formatPrice(material.price, true),
                  styles: {halign: 'center', valign: 'middle'}
                },
                {content: material.count, styles: {halign: 'center', valign: 'middle'}},
                {content: material.unitType ? material.unitType : '', styles: {halign: 'center', valign: 'middle'}},
                {content: mixins.methods.formatPrice(totalPrice, true), styles: {halign: 'center', valign: 'middle'}},
                {content: nds ? `${nds}%` : '0%', styles: {halign: 'center', valign: 'middle'}},
                {content: mixins.methods.formatPrice(ndsPrice, true), styles: {halign: 'center', valign: 'middle'}},
                {
                  content: mixins.methods.formatPrice(totalPriceWithNds, true),
                  styles: {halign: 'center', valign: 'middle'}
                },
              ]
              rows.push(row)
            })

            pdfFile.autoTable({
              theme: 'grid',
              startY: pdfCurrentPositionY + (pdfRowHeight / 2),
              body: rows,
              bodyStyles: {
                cellPadding: 1,
                lineColor: [0, 0, 0],
                textColor: [0, 0, 0]
              },
              styles: {
                font: pdfFontNormal,
                fontSize: pdfFontSize,
              },
              didParseCell: hook => {
                if (hook.row.index === 0) {
                  hook.cell.styles.font = pdfFontBold
                }
              },
              didDrawPage: hook => {
                pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
              }
            })
          }

          if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
            pdfFile.addPage()
            pdfCurrentPositionY = pdfRowHeight * 2
          }

          if (Object.keys(materials).length) {
            pdfFile.text(
              `Итого сумма без НДС: ${mixins.methods.formatPrice(totalMaterialsPrice, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }
            pdfFile.text(
              `в т.ч. сумма НДС 10%: ${mixins.methods.formatPrice(totalMaterialsNds10Price, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }
            pdfFile.text(
              `в т.ч. сумма НДС 20%: ${mixins.methods.formatPrice(totalMaterialsNds20Price, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }
            pdfFile.text(
              `Итого сумма с учетом НДС: ${mixins.methods.formatPrice(totalMaterialsPriceWithNds, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }
          }

          pdfFile.text(
            'Вышеперечисленные услуги выполнены полностью. Заказчик по объему, качеству и срокам оказания услуг',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text(
            'претензий не имеет. Настоящий акт является основанием к оплате оказанных услуг.',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += (pdfRowHeight * 2)

          pdfFile.setFont(pdfFontBold)
          if (pdfHeight - 10 < pdfCurrentPositionY) {
            pdfFile.addPage()
            pdfCurrentPositionY = 10
          }
          pdfFile.text('ИТОГО', pdfMargin, pdfCurrentPositionY)
          pdfCurrentPositionY += pdfRowHeight

          let totalText = 'Начислено:   '
          pdfFile.text(totalText, pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(
            mixins.methods.formatPrice(totalPrice, true),
            pdfMargin + pdfFile.getTextWidth(totalText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)

          let discountText = 'Скидка %:   '
          pdfFile.text(discountText, (pdfWidth / 4) + 4, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            discountPercent ? discountPercent.toString() : '0',
            (pdfWidth / 4) + 4 + pdfFile.getTextWidth(discountText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)
          let payText = 'К оплате:   '
          pdfFile.text(payText, pdfWidth / 2, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            (salePrice > 0 && totalPrice) || discountPercent === 100
              ? mixins.methods.formatPrice(salePrice, true)
              : mixins.methods.formatPrice(totalPrice, true),
            (pdfWidth / 2) + pdfFile.getTextWidth(payText),
            pdfCurrentPositionY
          )

          pdfCurrentPositionY += (pdfRowHeight * 2)
          pdfFile.setFont(pdfFontBold)
          pdfFile.setDrawColor(0,0,0,1)
          let cashierText = mixins.methods.getSignerLabelForAct()
          pdfFile.text(cashierText, pdfMargin, pdfCurrentPositionY)
          const offset = pdfMargin + pdfFile.getTextWidth(cashierText)
          if (actFillSignerDoctorName() && treatmentDiary.doctor) {
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.doctor.full_name_initials, offset, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontBold)
          }
          pdfFile.line(offset, pdfCurrentPositionY + 1, offset + 40, pdfCurrentPositionY + 1)

          let patientText = `${actPatientLabel()}: `
          pdfFile.text(patientText, pdfWidth / 2, pdfCurrentPositionY)

          pdfFile.line(
            (pdfWidth / 2) + pdfFile.getTextWidth(patientText),
            pdfCurrentPositionY + 1,
            pdfWidth - pdfMargin,
            pdfCurrentPositionY + 1,
          )

          pdfFile.save(
            mixins.methods.getActTitle(treatmentDiary) + ' (с материалами) - ' + patient.full_name + '.pdf'
          )
        })
      }
    },
    async createStatementOfWorkPerformedForMedicalServicesRendered(treatmentDiary, patient, discountPercent) {
      const userData = getUserData()
      if (userData) {
        await store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            showCommonToast(
              'Ошибка формирования акта выполненных работ по оказанным медицинским услугам',
              'Пожалуйста, обратитесь в службу технической поддержки',
              'danger'
            )
            return false
          }
          const priceCalcSystem = mixins.methods.getMaterialPriceCalcSystem()
          const pdfFont = 'Times-Roman'
          const pdfFontBold = 'bold'
          const pdfFontNormal = 'normal'
          const pdfLeftMargin = 16
          const pdfRightMargin = pdfLeftMargin / 2
          const pdfFontSize = 12
          const pdfRowHeight = 7
          const pdfWidth = 210
          let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})

          // header
          pdfFile = mixins.methods.getPdfDocumentHeader(pdfFile, integrator, pdfFont, pdfLeftMargin)
          let positionY = mixins.methods.getPositionAfterHeader(integrator, 44, false)

          pdfFile.setFont(pdfFont, pdfFontNormal)
          pdfFile.setFontSize(pdfFontSize)
          pdfFile.text(
            'Акт выполненных работ по оказанным медицинским услугам',
            pdfWidth / 2,
            positionY,
            'center'
          )
          positionY += pdfRowHeight * 1.5
          pdfFile.text(integrator.city ? integrator.city : '', pdfLeftMargin, positionY)
          const date = moment(parseInt(treatmentDiary.date)).format('ll')
          pdfFile.text(date, pdfWidth - pdfRightMargin - pdfMixins.methods.getTextWidth(pdfFile, date), positionY)

          positionY += pdfRowHeight * 1.5
          const patientInfo = [patient.full_name]
          if (patient.address) {
            patientInfo.push(patient.address)
          }

          if (
            patient.document_series
            && patient.document_number
            && patient.document_issued_by
            && patient.document_type === 'passport'
          ) {
            const passportInfo = [`${patient.document_series}${patient.document_number}`, patient.document_issued_by]
            patientInfo.push(`№ ${passportInfo.join(', ')}`)
          }
          let text = `${integrator.name}, в соответствии с договором возмездного оказания медицинских услуг от `
            + `${moment(parseInt(treatmentDiary.date)).format('L')} г., `
            + `были оказаны медицинские услуги пациенту ${patientInfo.join(', ')}, наименование медицинских услуг:`
          const splittedText = pdfFile.splitTextToSize(text, pdfWidth - (pdfLeftMargin + pdfRightMargin))
          const splittedTextItemsCount = splittedText.length
          pdfFile.text(splittedText, pdfLeftMargin, positionY)
          positionY += (pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor) * splittedTextItemsCount

          text = [...new Set(treatmentDiary.dental_services_history.map(s => s.name))].join(', ')
          text = mixins.methods.capitalizeFirstLetter(text)
          if (pdfMixins.methods.getTextWidth(pdfFile, text) > pdfWidth - (pdfLeftMargin + pdfRightMargin)) {
            const splittedText = pdfFile.splitTextToSize(text, pdfWidth - (pdfLeftMargin + pdfRightMargin))
            const splittedTextItemsCount = splittedText.length
            pdfFile.text(splittedText, pdfLeftMargin, positionY)
            positionY += (pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor) * (splittedTextItemsCount - 1)
          } else {
            pdfFile.text(text, pdfLeftMargin, positionY)
          }
          positionY += pdfRowHeight * 1.5

          pdfFile.setFont(pdfFont, pdfFontBold)
          let totalPrice = treatmentDiary.dental_services_history.reduce(
            (totalPrice, service) =>
              totalPrice + parseFloat(
                mixins.methods.formatPrice(service.total_price * service.count, false)
              ), 0
          )
          totalPrice = parseFloat(mixins.methods.formatPrice(totalPrice, false))
          const discountAmount = treatmentDiary.dental_services_history.length
            ? getPaymentDiscountAmountByTreatmentDiary(treatmentDiary, discountPercent)
            : (discountPercent / 100) * totalPrice
          let salePrice = discountPercent ? (totalPrice - discountAmount) : totalPrice
          salePrice = salePrice < 0 ? 0 : salePrice
          text = `Выполнено работ на сумму с НДС: ${mixins.methods.formatPrice(salePrice, false)}`
          pdfFile.text(text, pdfLeftMargin, positionY)
          let endTextPosition = pdfLeftMargin + pdfMixins.methods.getTextWidth(pdfFile, text)
          text = `(${mixins.methods.amountToHumanReadableAmount(salePrice)})`
          pdfFile.setFont(pdfFont, pdfFontNormal)
          let startTextPosition = endTextPosition + 1
          pdfFile.text(text, startTextPosition, positionY)
          positionY += 1
          pdfFile.line(pdfLeftMargin, positionY, pdfWidth - pdfRightMargin, positionY)

          positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
          text = 'Услуги без НДС (согласно ст.118 подп. 1.2  п.1 НК РБ)'
          pdfFile.text(text, pdfLeftMargin, positionY)

          let totalServicesNds10Price = 0
          let totalServicesNds20Price = 0
          let totalMaterialsNds10Price = 0
          let totalMaterialsNds20Price = 0
          treatmentDiary.dental_services_history.forEach(s => {
            const totalPrice = s.service_price * s.count
            const ndsPrice = s.nds_value ? (totalPrice / 100) * s.nds_value : null
            if (s.nds_value === 10) {
              totalServicesNds10Price += ndsPrice
            }
            if (s.nds_value === 20) {
              totalServicesNds20Price += ndsPrice
            }
            s.materials.map(m => {
              const totalPrice = m.price * s.count * m.part_rate
              const ndsPrice = m.nds_value ? (totalPrice / 100) * m.nds_value : null
              if (m.nds_value === 10) {
                if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
                  totalMaterialsNds10Price += (m.price_per_unit - m.price_per_unit_without_nds) * s.count * m.part_rate
                } else {
                  totalMaterialsNds10Price += ndsPrice
                }
              }
              if (m.nds_value === 20) {
                if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
                  totalMaterialsNds20Price += (m.price_per_unit - m.price_per_unit_without_nds) * s.count * m.part_rate
                } else {
                  totalMaterialsNds20Price += ndsPrice
                }
              }
            })
            totalMaterialsNds10Price = parseFloat(
              mixins.methods.formatPrice(totalMaterialsNds10Price, false)
            )
            totalMaterialsNds20Price = parseFloat(
              mixins.methods.formatPrice(totalMaterialsNds20Price, false)
            )
          })

          if (
            totalMaterialsNds10Price || totalMaterialsNds20Price || totalServicesNds10Price || totalServicesNds20Price
          ) {
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            text = 'В том числе НДС:'
            pdfFile.text(text, pdfLeftMargin, positionY)
          }

          if (totalMaterialsNds10Price || totalMaterialsNds20Price) {
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            text = 'на материалы'
            pdfFile.text(text, pdfLeftMargin, positionY)
          }

          if (totalMaterialsNds10Price) {
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            totalMaterialsNds10Price = totalMaterialsNds10Price.toFixed(2)
            text = `НДС 10%: ${totalMaterialsNds10Price} (${mixins.methods.amountToHumanReadableAmount(totalMaterialsNds10Price)})`
            pdfFile.text(text, pdfLeftMargin, positionY)
            positionY += 1
            pdfFile.line(
              pdfLeftMargin, positionY, pdfLeftMargin + pdfMixins.methods.getTextWidth(pdfFile, text), positionY
            )
          }

          if (totalMaterialsNds20Price) {
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            totalMaterialsNds20Price = totalMaterialsNds20Price.toFixed(2)
            text = `НДС 20%: ${totalMaterialsNds20Price} (${mixins.methods.amountToHumanReadableAmount(totalMaterialsNds20Price)})`
            pdfFile.text(text, pdfLeftMargin, positionY)
            positionY += 1
            pdfFile.line(
              pdfLeftMargin, positionY, pdfLeftMargin + pdfMixins.methods.getTextWidth(pdfFile, text), positionY
            )
          }

          if (totalServicesNds10Price || totalServicesNds20Price) {
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            text = 'на тариф'
            pdfFile.text(text, pdfLeftMargin, positionY)
          }

          if (totalServicesNds10Price) {
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            totalServicesNds10Price = totalServicesNds10Price.toFixed(2)
            text = `НДС 10%: ${totalServicesNds10Price} (${mixins.methods.amountToHumanReadableAmount(totalServicesNds10Price)})`
            pdfFile.text(text, pdfLeftMargin, positionY)
            positionY += 1
            pdfFile.line(
              pdfLeftMargin, positionY, pdfLeftMargin + pdfMixins.methods.getTextWidth(pdfFile, text), positionY
            )
          }

          if (totalServicesNds20Price) {
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            totalServicesNds20Price = totalServicesNds20Price.toFixed(2)
            text = `НДС 20%: ${totalServicesNds20Price} (${mixins.methods.amountToHumanReadableAmount(totalServicesNds20Price)})`
            pdfFile.text(text, pdfLeftMargin, positionY)
            positionY += 1
            pdfFile.line(
              pdfLeftMargin, positionY, pdfLeftMargin + pdfMixins.methods.getTextWidth(pdfFile, text), positionY
            )
          }

          positionY += pdfRowHeight * 1.5
          const doctor = treatmentDiary.doctor ? treatmentDiary.doctor.full_name_initials : ''
          text = `Врач оказавший услуги: ${doctor}`
          pdfFile.text(text, pdfLeftMargin, positionY)
          positionY += 1
          const offset = treatmentDiary.doctor
            ? pdfLeftMargin + pdfMixins.methods.getTextWidth(pdfFile, text)
            : pdfLeftMargin + (pdfMixins.methods.getTextWidth(pdfFile, text) * 2)
          pdfFile.line(pdfLeftMargin, positionY, offset, positionY)

          positionY += pdfRowHeight * 1.5
          text = 'Качество оказанных услуг соответствует предъявленным требованиям, стороны претензий друг к'
          pdfFile.text(text, pdfLeftMargin, positionY)

          positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
          text = 'другу не имеют.'
          pdfFile.text(text, pdfLeftMargin, positionY)

          const pageCenter = pdfWidth / 2
          positionY += pdfRowHeight * 1.5
          text = 'Исполнитель'
          pdfFile.text(text, pdfLeftMargin, positionY)

          positionY += 1
          pdfFile.line(
            pdfLeftMargin + pdfMixins.methods.getTextWidth(pdfFile, text) + 10, positionY, pageCenter - 10,
            positionY
          )
          positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
          text = '(подпись)'
          pdfFile.text(text, pdfLeftMargin + pdfMixins.methods.getTextWidth(pdfFile, text) + 30, positionY)

          positionY -= pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
          positionY -= 1
          text = actPatientLabel()
          pdfFile.text(text, pageCenter, positionY)

          positionY += 1
          pdfFile.line(
            pageCenter + pdfMixins.methods.getTextWidth(pdfFile, text) + 10, positionY,
            pdfWidth - pdfLeftMargin - 10, positionY
          )
          positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
          text = '(подпись)'
          pdfFile.text(text, pageCenter + pdfMixins.methods.getTextWidth(pdfFile, text) + 26, positionY)

          pdfFile.save(
            'Акт выполненных работ по оказанным медицинским услугам - ' + patient.full_name + '.pdf'
          )
        })
      }
    },
    async createActOfComplexServicesRenderedForTreatmentDiary(treatmentDiary, patient, discountPercent) {
      const userData = getUserData()
      if (userData) {
        await store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            showCommonToast(
              'Ошибка формирования акта оказанных комплексов услуг',
              'Пожалуйста, обратитесь в службу технической поддержки',
              'danger'
            )
            return false
          }
          const pdfFontNormal = 'OpenSans-Regular'
          const pdfFontBold = 'OpenSans-Bold'
          const pdfMargin = 8
          const pdfFontSize = 10
          const pdfRowHeight = 7
          const pdfWidth = 210
          const clinicName = integrator.name ? mixins.methods.formatText(integrator.name) : ''
          const priceCalcSystem = mixins.methods.getMaterialPriceCalcSystem()

          let pdfCurrentPositionY = 0
          let pdfFile = new jsPDF({
            orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true
          })
          const pdfHeight = pdfFile.internal.pageSize.height

          // header
          pdfFile = mixins.methods.getPdfDocumentHeader(pdfFile, integrator, pdfFontNormal, pdfMargin)
          pdfCurrentPositionY = mixins.methods.getPositionAfterHeader(integrator, 42, false)

          pdfFile.setFontSize(pdfFontSize)
          let patientName = patient.full_name

          pdfFile.setFont(pdfFontBold)
          mixins.methods.setActTitle(pdfFile, integrator, treatmentDiary, pdfWidth, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          if (integrator.city) {
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Место составления ' + integrator.city, pdfWidth - pdfMargin, pdfCurrentPositionY, 'right')
          }

          pdfCurrentPositionY += (pdfRowHeight * 2)

          let rightDataPosition = pdfWidth / 5
          rightDataPosition = treatmentDiary.nurse ? rightDataPosition + 2 : rightDataPosition
          pdfFile.setFont(pdfFontBold)
          pdfFile.text('Дата:', pdfMargin, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)

          let stringDate = moment(parseInt(treatmentDiary.date)).format('L')
          pdfFile.text(stringDate, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(
            rightDataPosition,
            pdfCurrentPositionY + 1,
            rightDataPosition + pdfFile.getTextWidth(stringDate),
            pdfCurrentPositionY + 1,
          )

          if (!actContractNumberHidden()) {
            pdfFile.setFont(pdfFontBold)
            let actText = 'Договор №:  '
            pdfFile.text(actText, pdfWidth / 2, pdfCurrentPositionY, 'center')
            pdfFile.setFont(pdfFontNormal)
            const contractNumber = mixins.methods.getActContractNumber(treatmentDiary, patient)
            const contractNumberWidth = pdfMixins.methods.getTextWidth(pdfFile, contractNumber)
            const actTextWidth = pdfMixins.methods.getTextWidth(pdfFile, actText)
            pdfFile.text(contractNumber, (pdfWidth / 2) + (actTextWidth / 2) + 1, pdfCurrentPositionY)
            pdfFile.line(
              (pdfWidth / 2) + (actTextWidth / 2),
              pdfCurrentPositionY + 1,
              (pdfWidth / 2) + ((actTextWidth / 2) + contractNumberWidth + 2),
              pdfCurrentPositionY + 1
            )
          }

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Заказчик:', pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(patientName, rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

          pdfFile.setFont(pdfFontBold)
          pdfCurrentPositionY += pdfRowHeight

          pdfFile.text('Адрес:', pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(patient.address ? patient.address : '', rightDataPosition, pdfCurrentPositionY)
          pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

          if (treatmentDiary.doctor) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О врача:', pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.doctor.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          if (treatmentDiary.lab_technician) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О лаборанта:', pdfMargin, pdfCurrentPositionY)

            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.lab_technician.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          if (treatmentDiary.nurse) {
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight
            pdfFile.text('Ф.И.О мед. сестры:', pdfMargin, pdfCurrentPositionY)

            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.nurse.full_name + '', rightDataPosition, pdfCurrentPositionY)
            pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
          }

          pdfCurrentPositionY += (pdfRowHeight * 2)

          pdfFile.setFont(pdfFontBold)
          if (treatmentDiary.type === 'DENTAL') {
            const teethNumbers = []
            const teethTitle = 'Зуб(ы): '
            const teethTitleWidth = pdfMixins.methods.getTextWidth(pdfFile, teethTitle)
            treatmentDiary.status_teeth.map(t => t.teeth ? teethNumbers.push(t.teeth.number) : null)
            pdfFile.text(teethTitle, pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(
              teethNumbers.length ? teethNumbers.join(',') : 'Не указаны',
              pdfMargin + teethTitleWidth,
              pdfCurrentPositionY
            )
            pdfFile.setFont(pdfFontBold)
            pdfCurrentPositionY += pdfRowHeight

            if (!mixins.methods.isWarrantyHiddenInDocument()) {
              const warrantyTitle = 'Гарантия: '
              const warrantyTitleWidth = pdfMixins.methods.getTextWidth(pdfFile, warrantyTitle)
              const warrantyText = treatmentDiary.without_warranty
                ? 'Без гарантии' : (treatmentDiary.warranty ? `${treatmentDiary.warranty.toString()} мес.` : '')
              pdfFile.text(warrantyTitle, pdfMargin, pdfCurrentPositionY)
              pdfFile.setFont(pdfFontNormal)
              pdfFile.text(warrantyText, pdfMargin + warrantyTitleWidth, pdfCurrentPositionY)
              pdfFile.setFont(pdfFontBold)
              pdfCurrentPositionY += pdfRowHeight
            }
          }

          if (!mixins.methods.isDiagnosisHiddenInDocument()) {
            let label = 'Диагноз: '
            const diagnosisLabelWidth = pdfMixins.methods.getTextWidth(pdfFile, label)
            pdfFile.text(label, pdfMargin, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontNormal)
            const diagnosis = treatmentDiary && treatmentDiary.diagnosis_custom_name
              ? treatmentDiary.diagnosis_custom_name
              : (
                treatmentDiary.diagnoses.length
                  ? treatmentDiary.diagnoses.map(d => d.full_name).join(', ')
                  : 'Без диагноза'
              )
            if (pdfMixins.methods.getTextWidth(pdfFile, diagnosis) > pdfWidth - diagnosisLabelWidth - pdfMargin * 2) {
              const splittedText = pdfFile.splitTextToSize(
                diagnosis, pdfWidth - diagnosisLabelWidth - pdfMargin * 2
              )
              pdfFile.text(splittedText[0], pdfMargin + diagnosisLabelWidth, pdfCurrentPositionY)
              pdfCurrentPositionY += pdfRowHeight
              pdfFile.text(splittedText.slice(1), pdfMargin, pdfCurrentPositionY)
            } else {
              pdfFile.text(diagnosis, pdfMargin + diagnosisLabelWidth, pdfCurrentPositionY)
            }
          } else {
            pdfFile.setFont(pdfFontNormal)
            pdfCurrentPositionY -= pdfRowHeight
          }

          // Table services title
          const title = pdfMixins.methods.getServicesTableTitleForActOfServicesRendered(clinicName)
          const titleLength = title.length
          let splittedTitleItemsCount = 0
          pdfCurrentPositionY += pdfRowHeight
          if (titleLength + pdfMargin > pdfCurrentPositionY) {
            const splittedFirstPartStr = title.substring(
              0, pdfCurrentPositionY - 1 - pdfMargin
            ).split(' ')
            splittedFirstPartStr.splice(splittedFirstPartStr.length - 1, 1)
            const firstStr = splittedFirstPartStr.join(' ').trim()
            let newStr = title.substring(title.indexOf(firstStr) + firstStr.length, title.length).trim()
            pdfFile.text(firstStr, pdfMargin, pdfCurrentPositionY)
            const splittedConclusion = pdfFile.splitTextToSize(newStr, pdfWidth - (pdfMargin * 2))
            splittedTitleItemsCount = splittedConclusion.length
            pdfFile.setLineHeightFactor(2)
            pdfFile.text(splittedConclusion, pdfMargin, pdfCurrentPositionY + 8)
            pdfCurrentPositionY += (pdfRowHeight * splittedTitleItemsCount)
            pdfFile.setLineHeightFactor(1)
          } else {
            pdfFile.text(title, pdfMargin, pdfCurrentPositionY)
          }

          const additionalServices = []
          const tPCount = {}
          if (treatmentDiary.treatment_plans) {
            treatmentDiary.treatment_plans.map(
              tp => tp.id in tPCount
                ? tPCount[tp.id] += tp.system_column_count : tPCount[tp.id] = tp.system_column_count
            )
          }
          const showNdsInfoInDocument = !!mixins.methods.showNdsInfoInDocument()
          let totalPrice = 0
          let totalMaterialsPrice = 0
          let totalMaterialsNds10Price = 0
          let totalMaterialsNds20Price = 0
          let totalMaterialsPriceWithNds = 0
          const treatmentPlansTableData = {}
          treatmentDiary.dental_services_history.map(s => {
            if (s.treatment_plan && treatmentDiary.treatment_plans) {
              const index = treatmentDiary.treatment_plans.findIndex(plan => plan.id === s.treatment_plan.id)
              if (index > -1) {
                const plan = treatmentDiary.treatment_plans[index]
                if (s.treatment_plan.id in treatmentPlansTableData) {
                  treatmentPlansTableData[s.treatment_plan.id].amount +=
                    parseFloat(mixins.methods.formatPrice(s.total_price * s.count, false))
                } else {
                  treatmentPlansTableData[s.treatment_plan.id] = {
                    number: plan.number,
                    name: plan.name,
                    count: s.treatment_plan.id in tPCount ? tPCount[s.treatment_plan.id] : 0,
                    price: plan.user_price ? plan.user_price : plan.price,
                    amount: parseFloat(
                      mixins.methods.formatPrice(s.total_price * s.count, false)
                    )
                  }
                }
              }
            } else {
              additionalServices.push(s)
            }

            if (showNdsInfoInDocument) {
              const materials = s.materials && s.materials.length ? s.materials : []
              materials.map(m => {
                const nds = m.nds_value
                const pricePerUnit = m.price
                const totalPrice = pricePerUnit * s.count * m.part_rate
                let ndsPrice
                if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
                  ndsPrice = (m.price_per_unit - m.price_per_unit_without_nds) * s.count * m.part_rate
                } else {
                  ndsPrice = nds ? (totalPrice / 100) * nds : null
                }
                const totalPriceWithNds = ndsPrice ? totalPrice + ndsPrice : totalPrice
                if (nds === 10) {
                  totalMaterialsNds10Price += ndsPrice
                }
                if (nds === 20) {
                  totalMaterialsNds20Price += ndsPrice
                }
                totalMaterialsPrice += totalPrice
                totalMaterialsPriceWithNds += totalPriceWithNds
              })
              totalMaterialsNds10Price = parseFloat(
                mixins.methods.formatPrice(totalMaterialsNds10Price, false)
              )
              totalMaterialsNds20Price = parseFloat(
                mixins.methods.formatPrice(totalMaterialsNds20Price, false)
              )
              totalMaterialsPriceWithNds = parseFloat(
                mixins.methods.formatPrice(totalMaterialsPriceWithNds, false)
              )
            }
          })

          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text('Комплексы услуг:', pdfMargin, pdfCurrentPositionY)

          const tablesHeader = [
            {content: 'Номер', styles: {halign: 'center'}},
            {content: 'Наименование', styles: {halign: 'center'}},
            {content: 'Количество', styles: {halign: 'center'}},
            {content: 'Цена', styles: {halign: 'center'}},
            {content: 'Сумма', styles: {halign: 'center'}}
          ]
          let rows = [tablesHeader]

          let totalPlansPrice = 0
          Object.values(treatmentPlansTableData).map(plan => {
            totalPlansPrice += plan.amount
            rows.push([
              {content: plan.number, styles: {halign: 'center'}},
              {content: plan.name, styles: {halign: 'left'}},
              {content: plan.count, styles: {halign: 'center'}},
              {content: mixins.methods.formatPrice(plan.price, true), styles: {halign: 'center'}},
              {content: mixins.methods.formatPrice(plan.amount, true), styles: {halign: 'center'}},
            ])
          })

          rows.push([
            {content: 'Итого', styles: {halign: 'left'}, colSpan: 4},
            {
              content: mixins.methods.formatPrice(totalPlansPrice, true), styles: { halign: 'center' }
            }
          ])
          totalPrice = treatmentDiary.dental_services_history.reduce(
            (totalPrice, service) =>
              totalPrice + parseFloat(
                mixins.methods.formatPrice(service.total_price * service.count, false)
              ), 0
          )
          totalPrice = parseFloat(mixins.methods.formatPrice(totalPrice, false))
          const discountAmount = treatmentDiary.dental_services_history.length
            ? getPaymentDiscountAmountByTreatmentDiary(treatmentDiary, discountPercent)
            : (discountPercent / 100) * totalPrice
          let salePrice = discountPercent ? (totalPrice - discountAmount) : totalPrice
          salePrice = salePrice < 0 ? 0 : salePrice
          pdfFile.autoTable({
            theme: 'grid',
            startY: pdfCurrentPositionY + (pdfRowHeight / 2),
            body: rows,
            bodyStyles: {
              cellPadding: 1,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0]
            },
            styles: {
              font: pdfFontNormal,
              fontSize: pdfFontSize,
            },
            didParseCell: hook => {
              if (hook.row.index === 0) {
                hook.cell.styles.font = pdfFontBold
              }
            },
            didDrawPage: hook => {
              pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
            }
          })

          if (additionalServices.length) {
            pdfFile.text('Дополнительные услуги:', pdfMargin, pdfCurrentPositionY)
            rows = [tablesHeader]

            let totalServicesPrice = 0
            additionalServices.map(service => {
              totalServicesPrice += service.total_price * service.count
              rows.push([
                {content: service.number, styles: {halign: 'center'}},
                {content: service.name},
                {content: service.count, styles: {halign: 'center'}},
                {
                  content: mixins.methods.formatPrice(service.total_price, true),
                  styles: {halign: 'center'}
                },
                {
                  content: mixins.methods.formatPrice(service.total_price * service.count, true),
                  styles: {halign: 'center'}
                }
              ])
            })

            rows.push([
              {content: 'Итого', styles: {halign: 'left'}, colSpan: 4},
              {
                content: mixins.methods.formatPrice(totalServicesPrice, true),
                styles: { halign: 'center' }
              }
            ])

            pdfFile.autoTable({
              theme: 'grid',
              startY: pdfCurrentPositionY + (pdfRowHeight / 2),
              body: rows,
              bodyStyles: {
                cellPadding: 1,
                lineColor: [0, 0, 0],
                textColor: [0, 0, 0]
              },
              styles: {
                font: pdfFontNormal,
                fontSize: pdfFontSize,
              },
              didParseCell: hook => {
                if (hook.row.index === 0) {
                  hook.cell.styles.font = pdfFontBold
                }
              },
              didDrawPage: hook => {
                pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
              }
            })
          }

          if (showNdsInfoInDocument && totalMaterialsPrice) {
            pdfFile.text(
              `Итого сумма без НДС: ${mixins.methods.formatPrice(totalMaterialsPrice, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }

            pdfFile.text(
              `в т.ч. сумма НДС 10%: ${mixins.methods.formatPrice(totalMaterialsNds10Price, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }

            pdfFile.text(
              `в т.ч. сумма НДС 20%: ${mixins.methods.formatPrice(totalMaterialsNds20Price, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }

            pdfFile.text(
              `Итого сумма с учетом НДС: ${mixins.methods.formatPrice(totalMaterialsPriceWithNds, true)}`,
              pdfMargin,
              pdfCurrentPositionY
            )
            if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
              pdfFile.addPage()
              pdfCurrentPositionY = pdfRowHeight * 2
            } else {
              pdfCurrentPositionY += pdfRowHeight
            }
          }

          pdfFile.text(
            'Вышеперечисленные услуги выполнены полностью. Заказчик по объему, качеству и срокам оказания услуг',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += pdfRowHeight
          pdfFile.text(
            'претензий не имеет. Настоящий акт является основанием к оплате оказанных услуг.',
            pdfMargin,
            pdfCurrentPositionY
          )
          pdfCurrentPositionY += (pdfRowHeight * 2)

          pdfFile.setFont(pdfFontBold)
          pdfFile.text('ИТОГО', pdfMargin, pdfCurrentPositionY)
          pdfCurrentPositionY += pdfRowHeight

          let totalText = 'Начислено:   '
          pdfFile.text(totalText, pdfMargin, pdfCurrentPositionY)

          pdfFile.setFont(pdfFontNormal)

          pdfFile.text(
            mixins.methods.formatPrice(totalPrice, true),
            pdfMargin + pdfFile.getTextWidth(totalText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)

          let discountText = 'Скидка %:   '
          pdfFile.text(discountText, (pdfWidth / 4) + 4, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            discountPercent ? discountPercent.toString() : '0',
            (pdfWidth / 4) + 4 + pdfFile.getTextWidth(discountText),
            pdfCurrentPositionY
          )

          pdfFile.setFont(pdfFontBold)
          let payText = 'К оплате:   '
          pdfFile.text(payText, pdfWidth / 2, pdfCurrentPositionY)
          pdfFile.setFont(pdfFontNormal)
          pdfFile.text(
            (salePrice > 0 && totalPrice) || discountPercent === 100
              ? mixins.methods.formatPrice(salePrice, true)
              : mixins.methods.formatPrice(totalPrice, true),
            (pdfWidth / 2) + pdfFile.getTextWidth(payText),
            pdfCurrentPositionY
          )

          pdfCurrentPositionY += (pdfRowHeight * 2)
          pdfFile.setFont(pdfFontBold)
          pdfFile.setDrawColor(0,0,0,1)
          let cashierText = mixins.methods.getSignerLabelForAct()
          pdfFile.text(cashierText, pdfMargin, pdfCurrentPositionY)
          const offset = pdfMargin + pdfFile.getTextWidth(cashierText)
          if (actFillSignerDoctorName() && treatmentDiary.doctor) {
            pdfFile.setFont(pdfFontNormal)
            pdfFile.text(treatmentDiary.doctor.full_name_initials, offset, pdfCurrentPositionY)
            pdfFile.setFont(pdfFontBold)
          }
          pdfFile.line(offset, pdfCurrentPositionY + 1, offset + 40, pdfCurrentPositionY + 1)

          let patientText = `${actPatientLabel()}: `
          pdfFile.text(patientText, pdfWidth / 2, pdfCurrentPositionY)

          pdfFile.line(
            (pdfWidth / 2) + pdfFile.getTextWidth(patientText),
            pdfCurrentPositionY + 1,
            pdfWidth - pdfMargin,
            pdfCurrentPositionY + 1,
          )

          pdfFile.save(
            mixins.methods.getActTitle(treatmentDiary) + ' (комплексы услуг) - ' + patient.full_name + '.pdf'
          )
        })
      }
    },
    async createPurchaseOrder(treatmentDiary, patient) {
      const userData = getUserData()
      if (userData) {
        await store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            showCommonToast(
              'Ошибка формирования заказ-наряда', 'Пожалуйста, обратитесь в службу технической поддержки', 'danger'
            )
            return false
          }
          const priceCalcSystem = mixins.methods.getMaterialPriceCalcSystem()
          let pdfFont = 'Times-Roman'
          const pdfFontBold = 'bold'
          const pdfFontNormal = 'normal'
          const pdfMargin = 8
          const pdfFontSize = 11
          const pdfRowHeight = 7
          const date = moment(parseInt(treatmentDiary.date)).format('LL')
          let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})
          const pdfHeight = pdfFile.internal.pageSize.height
          const pdfWidth = pdfFile.internal.pageSize.width

          // header
          pdfFile = mixins.methods.getPdfDocumentHeader(pdfFile, integrator, pdfFont, pdfMargin)
          let positionY = mixins.methods.getPositionAfterHeader(integrator, 44, false)

          pdfFont = 'Microsoft-Sans-Serif'
          pdfFile.setFont(pdfFont, pdfFontBold)
          pdfFile.setFontSize(13.5)
          pdfFile.text(
            'Заказ-наряд',
            pdfWidth / 2,
            positionY,
            'center'
          )

          pdfFont = 'Times-Roman'
          pdfFile.setFont(pdfFont, pdfFontBold)
          pdfFile.setFontSize(pdfFontSize)

          positionY += pdfRowHeight * 1.5
          let text = 'Дата:'
          pdfFile.text(text, pdfMargin, positionY)
          pdfFile.setFont(pdfFont, pdfFontNormal)
          pdfFile.text(date, pdfMargin + pdfMixins.methods.getTextWidth(pdfFile, text) + 2, positionY)

          positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
          pdfFile.setFont(pdfFont, pdfFontBold)
          text = 'Пациент:'
          pdfFile.text(text, pdfMargin, positionY)
          pdfFile.setFont(pdfFont, pdfFontNormal)
          let patientInfo = [patient.full_name]
          if (patient.address) {
            patientInfo.push(patient.address)
          }

          if (
            patient.document_series
            && patient.document_number
            && patient.document_issued_by
            && patient.document_type === 'passport'
          ) {
            const passportInfo = [`${patient.document_series}${patient.document_number}`, patient.document_issued_by]
            patientInfo.push(`№ ${passportInfo.join(', ')}`)
          }
          patientInfo = patientInfo.join(', ')
          if (
            pdfMixins.methods.getTextWidth(pdfFile, patientInfo) > pdfWidth - pdfMixins.methods.getTextWidth(pdfFile, text) - pdfMargin * 2
          ) {
            const splittedText = pdfFile.splitTextToSize(
              patientInfo, pdfWidth - pdfMixins.methods.getTextWidth(pdfFile, text) - pdfMargin * 2
            )
            const splittedTextItemsCount = splittedText.length
            pdfFile.text(splittedText[0], pdfMargin + pdfMixins.methods.getTextWidth(pdfFile, text) + 2, positionY)
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            pdfFile.text(splittedText.slice(1), pdfMargin, positionY)
            positionY += (pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor) * (splittedTextItemsCount - 1)
          } else {
            pdfFile.text(patientInfo, pdfMargin + pdfMixins.methods.getTextWidth(pdfFile, text) + 2, positionY)
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
          }
          let rows = [
            [
              {content: 'Наименование услуги', styles: {cellWidth: 50, halign: 'center', valign: 'middle'}, rowSpan: 2},
              {content: 'Количество', styles: {cellWidth: 14, halign: 'center', valign: 'middle'}, rowSpan: 2},
              {content: 'Тариф услуги', styles: {halign: 'center', valign: 'middle'}, colSpan: 2},
              {content: 'Стоимость материалов', styles: {halign: 'center', valign: 'middle'}, colSpan: 5},
              {content: 'Всего', styles: {halign: 'center', valign: 'middle'}, rowSpan: 2},
            ],
            [
              {content: 'Без НДС', styles: {halign: 'center', valign: 'middle'}},
              {content: 'НДС', styles: {halign: 'center', valign: 'middle'}},
              {content: 'Освобожденных от НДС', styles: {halign: 'center', valign: 'middle'}},
              {content: 'Облагаемых НДС без учета НДС 10%', styles: {halign: 'center', valign: 'middle'}},
              {content: 'Облагаемых НДС без учета НДС 20%', styles: {halign: 'center', valign: 'middle'}},
              {content: 'Сумма НДС по ставке 10%', styles: {halign: 'center', valign: 'middle'}},
              {content: 'Сумма НДС по ставке 20%', styles: {halign: 'center', valign: 'middle'}},
            ],
          ]

          let totalServicesCount = 0
          let totalServicesPriceWithoutNds = 0
          let totalNdsPrice = 0
          let totalMaterialsPriceWithoutNds = 0
          let commonTotalMaterialsNds10Price = 0
          let commonTotalMaterialsNds20Price = 0
          let commonTotalMaterialsWithNds10Price = 0
          let commonTotalMaterialsWithNds20Price = 0
          let commonTotalPrice = 0
          let totalServicesNds10Price = 0
          let totalServicesNds20Price = 0
          treatmentDiary.dental_services_history.forEach(service => {
            let ndsPrice = 0
            const totalPrice =
              parseFloat(mixins.methods.formatPrice(service.total_price * service.count, false))
            if (service.nds_value) {
              const nds = service.nds_value
              ndsPrice = nds ? ((service.service_price * service.count / 100) * nds) : 0
              if (nds === 10) {
                totalServicesNds10Price += ndsPrice
              }
              if (nds === 20) {
                totalServicesNds20Price += ndsPrice
              }
            }

            let materialPriceWithoutNds = 0
            let totalMaterialsNds10Price = 0
            let totalMaterialsNds20Price = 0
            let totalMaterialsWithNds10Price = 0
            let totalMaterialsWithNds20Price = 0
            service.materials.map(m => {
              const nds = m.nds_value
              const pricePerUnit = m.price
              let materialPrice = pricePerUnit * service.count * m.part_rate
              let materialNdsPrice = nds ? (nds / 100) * materialPrice : 0
              materialPriceWithoutNds += !nds ? pricePerUnit * service.count * m.part_rate : 0
              if (nds === 10) {
                if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
                  totalMaterialsNds10Price += (
                    m.price_per_unit - m.price_per_unit_without_nds
                  ) * service.count * m.part_rate
                  totalMaterialsWithNds10Price += materialPrice
                } else {
                  totalMaterialsNds10Price += materialNdsPrice
                  totalMaterialsWithNds10Price += materialPrice
                }
              }
              if (nds === 20) {
                if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
                  totalMaterialsNds20Price += (
                    m.price_per_unit - m.price_per_unit_without_nds
                  ) * service.count * m.part_rate
                  totalMaterialsWithNds20Price += materialPrice
                } else {
                  totalMaterialsNds20Price += materialNdsPrice
                  totalMaterialsWithNds20Price += materialPrice
                }
              }
            })
            materialPriceWithoutNds = materialPriceWithoutNds ? materialPriceWithoutNds : 0

            let servicePriceWithoutNds = service.service_price * service.count
            servicePriceWithoutNds = servicePriceWithoutNds ? servicePriceWithoutNds : 0
            rows.push([
              {content: `${service.number} ${service.name}`, styles: {halign: 'left', valign: 'middle'}},
              {content: service.count, styles: {halign: 'center', valign: 'middle'}},
              {
                content: mixins.methods.formatPrice(servicePriceWithoutNds, false),
                styles: {halign: 'center', valign: 'middle'}
              },
              {
                content: mixins.methods.formatPrice(ndsPrice, false),
                styles: {halign: 'center', valign: 'middle'}
              },
              {
                content: mixins.methods.formatPrice(materialPriceWithoutNds, false),
                styles: {halign: 'center', valign: 'middle'}
              },
              {
                content: mixins.methods.formatPrice(totalMaterialsWithNds10Price, false),
                styles: {halign: 'center', valign: 'middle'}
              },
              {
                content: mixins.methods.formatPrice(totalMaterialsWithNds20Price, false),
                styles: {halign: 'center', valign: 'middle'}
              },
              {
                content: mixins.methods.formatPrice(totalMaterialsNds10Price, false),
                styles: {halign: 'center', valign: 'middle'}
              },
              {
                content: mixins.methods.formatPrice(totalMaterialsNds20Price, false),
                styles: {halign: 'center', valign: 'middle'}
              },
              {
                content: mixins.methods.formatPrice(totalPrice, false),
                styles: {halign: 'center', valign: 'middle'}
              }
            ])

            totalServicesCount += service.count
            totalServicesPriceWithoutNds += parseFloat(servicePriceWithoutNds)
            totalNdsPrice += ndsPrice
            totalMaterialsPriceWithoutNds += parseFloat(materialPriceWithoutNds)
            commonTotalMaterialsNds10Price += totalMaterialsNds10Price
            commonTotalMaterialsNds20Price += totalMaterialsNds20Price
            commonTotalMaterialsWithNds10Price += totalMaterialsWithNds10Price
            commonTotalMaterialsWithNds20Price += totalMaterialsWithNds20Price
            commonTotalPrice += parseFloat(totalPrice)
            commonTotalMaterialsNds10Price = parseFloat(
              mixins.methods.formatPrice(commonTotalMaterialsNds10Price, false)
            )
            commonTotalMaterialsNds20Price = parseFloat(
              mixins.methods.formatPrice(commonTotalMaterialsNds20Price, false)
            )
          })

          totalServicesPriceWithoutNds = totalServicesPriceWithoutNds ? totalServicesPriceWithoutNds : 0
          totalNdsPrice = totalNdsPrice ? totalNdsPrice : 0
          totalMaterialsPriceWithoutNds = totalMaterialsPriceWithoutNds ? totalMaterialsPriceWithoutNds : 0
          commonTotalPrice = commonTotalPrice ? commonTotalPrice : 0
          rows.push([
            {content: 'ИТОГО', styles: {halign: 'left', valign: 'middle'}},
            {content: totalServicesCount, styles: {halign: 'center', valign: 'middle'}},
            {
              content: mixins.methods.formatPrice(totalServicesPriceWithoutNds, false),
              styles: {halign: 'center', valign: 'middle'}
            },
            {
              content: mixins.methods.formatPrice(totalNdsPrice, false),
              styles: {halign: 'center', valign: 'middle'}
            },
            {
              content: mixins.methods.formatPrice(totalMaterialsPriceWithoutNds, false),
              styles: {halign: 'center', valign: 'middle'}
            },
            {
              content: mixins.methods.formatPrice(commonTotalMaterialsWithNds10Price, false),
              styles: {halign: 'center', valign: 'middle'}
            },
            {
              content: mixins.methods.formatPrice(commonTotalMaterialsWithNds20Price, false),
              styles: {halign: 'center', valign: 'middle'}},
            {
              content: mixins.methods.formatPrice(commonTotalMaterialsNds10Price, false),
              styles: {halign: 'center', valign: 'middle'}
            },
            {
              content: mixins.methods.formatPrice(commonTotalMaterialsNds20Price, false),
              styles: {halign: 'center', valign: 'middle'}
            },
            {
              content: mixins.methods.formatPrice(commonTotalPrice, false),
              styles: {halign: 'center', valign: 'middle'}
            }
          ])

          pdfFile.autoTable({
            theme: 'grid',
            startY: positionY,
            margin: pdfMargin,
            body: rows,
            bodyStyles: {
              cellPadding: 1,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0]
            },
            styles: {
              font: 'Microsoft-Sans-Serif',
              fontSize: 9,
            },
            didParseCell: hook => {
              hook.cell.styles.fontSize = hook.row.index > 1 && hook.column.index === 0 ? 7.5 : hook.cell.styles.fontSize
              hook.cell.styles.font = hook.row.index > 1 && hook.column.index === 0
                ? 'Microsoft-Sans-Serif' : hook.cell.styles.font
            },
            didDrawPage: hook => {
              positionY = (hook.cursor.y + pdfRowHeight)
            }
          })

          if (pdfHeight - 10 < positionY) {
            pdfFile.addPage()
            positionY = 10
          }

          pdfFile.setDrawColor(0,0,0,1)
          pdfFile.setFont(pdfFont, pdfFontBold)
          pdfFile.setFontSize(11)
          const mainText = `Выполнено работ на сумму с НДС: ${mixins.methods.formatPrice(commonTotalPrice, false)}`
          pdfFile.text(mainText, pdfMargin, positionY)
          text = `(${mixins.methods.priceToText(commonTotalPrice)})`
          pdfFile.setFont(pdfFont, pdfFontNormal)

          if (
            pdfMixins.methods.getTextWidth(pdfFile, text) > pdfWidth - pdfMixins.methods.getTextWidth(pdfFile, mainText) - pdfMargin * 2
          ) {
            const splittedText = pdfFile.splitTextToSize(
              text, pdfWidth - pdfMixins.methods.getTextWidth(pdfFile, mainText) - pdfMargin * 2
            )
            pdfFile.text(
              splittedText[0], pdfMargin + pdfMixins.methods.getTextWidth(pdfFile, mainText) + 4, positionY
            )
            pdfFile.line(pdfMargin, positionY + 1, pdfWidth - pdfMargin, positionY + 1)
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            pdfFile.text(splittedText.slice(1), pdfMargin, positionY)
            positionY += 1
            pdfFile.line(pdfMargin, positionY, pdfWidth - pdfMargin, positionY)
          } else {
            pdfFile.text(text, pdfMargin + pdfMixins.methods.getTextWidth(pdfFile, mainText) + 4, positionY)
            positionY += 1
            pdfFile.line(pdfMargin, positionY, pdfWidth - pdfMargin, positionY)
          }

          if (
            commonTotalMaterialsNds20Price || commonTotalMaterialsNds10Price
            || totalServicesNds10Price || totalServicesNds20Price
          ) {
            if (pdfHeight - 10 < positionY) {
              pdfFile.addPage()
              positionY = 10
            }
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            text = 'В том числе НДС:'
            pdfFile.text(text, pdfMargin, positionY)
          }

          if (commonTotalMaterialsNds20Price || commonTotalMaterialsNds10Price) {
            if (pdfHeight - 10 < positionY) {
              pdfFile.addPage()
              positionY = 10
            }
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            text = 'на материалы'
            pdfFile.text(text, pdfMargin, positionY)

            if (commonTotalMaterialsNds10Price) {
              if (pdfHeight - 10 < positionY) {
                pdfFile.addPage()
                positionY = 10
              }
              positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
              commonTotalMaterialsNds10Price = commonTotalMaterialsNds10Price.toFixed(2)
              text = `НДС 10%: ${commonTotalMaterialsNds10Price} (${mixins.methods.priceToText(commonTotalMaterialsNds10Price)})`
              pdfFile.text(text, pdfMargin, positionY)
              positionY += 1
              pdfFile.line(
                pdfMargin, positionY, pdfMargin + pdfMixins.methods.getTextWidth(pdfFile, text), positionY
              )
            }

            if (commonTotalMaterialsNds20Price) {
              if (pdfHeight - 10 < positionY) {
                pdfFile.addPage()
                positionY = 10
              }
              positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
              commonTotalMaterialsNds20Price = commonTotalMaterialsNds20Price.toFixed(2)
              text = `НДС 20%: ${commonTotalMaterialsNds20Price} (${mixins.methods.priceToText(commonTotalMaterialsNds20Price)})`
              pdfFile.text(text, pdfMargin, positionY)
              positionY += 1
              pdfFile.line(
                pdfMargin, positionY, pdfMargin + pdfMixins.methods.getTextWidth(pdfFile, text), positionY
              )
            }
          }

          if (totalServicesNds10Price || totalServicesNds20Price) {
            if (pdfHeight - 10 < positionY) {
              pdfFile.addPage()
              positionY = 10
            }
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            text = 'на тариф'
            pdfFile.text(text, pdfMargin, positionY)
          }

          if (totalServicesNds10Price) {
            if (pdfHeight - 10 < positionY) {
              pdfFile.addPage()
              positionY = 10
            }
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            totalServicesNds10Price = totalServicesNds10Price.toFixed(2)
            text = `НДС 10%: ${totalServicesNds10Price} (${mixins.methods.priceToText(totalServicesNds10Price)})`
            pdfFile.text(text, pdfMargin, positionY)
            positionY += 1
            pdfFile.line(pdfMargin, positionY, pdfMargin + pdfMixins.methods.getTextWidth(pdfFile, text), positionY)
          }

          if (totalServicesNds20Price) {
            if (pdfHeight - 10 < positionY) {
              pdfFile.addPage()
              positionY = 10
            }
            positionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
            totalServicesNds20Price = totalServicesNds20Price.toFixed(2)
            text = `НДС 20%: ${totalServicesNds20Price} (${mixins.methods.priceToText(totalServicesNds20Price)})`
            pdfFile.text(text, pdfMargin, positionY)
            positionY += 1
            pdfFile.line(pdfMargin, positionY, pdfMargin + pdfMixins.methods.getTextWidth(pdfFile, text), positionY)
          }

          positionY += pdfRowHeight * 1.5

          if (pdfHeight - 10 < positionY) {
            pdfFile.addPage()
            positionY = 10
          }

          text = `${purchaseOrderExecutorLabel()}:`
          pdfFile.text(text, pdfMargin, positionY)
          if (purchaseOrderFillExecutorDoctorName() && treatmentDiary.doctor) {
            pdfFile.text(
              treatmentDiary.doctor.full_name_initials,
              pdfMargin + 1 + pdfMixins.methods.getTextWidth(pdfFile, text),
              positionY
            )
          }
          positionY += 1
          pdfFile.line(pdfMargin, positionY, pdfWidth - pdfMargin, positionY)

          positionY += pdfRowHeight * 1.5
          if (pdfHeight - 10 < positionY) {
            pdfFile.addPage()
            positionY = 10
          }

          if (!purchaseOrderAdministratorHidden()) {
            text = 'Администратор:'
            pdfFile.text(text, pdfMargin, positionY)
            positionY += 1
            pdfFile.line(pdfMargin, positionY, pdfWidth - pdfMargin, positionY)
          }

          pdfFile.save('Заказ-наряд - ' + patient.full_name + ` (${date}).pdf`)
        })
      }
    },
    async createAcceptanceActOfRenderedMedicalServices(treatmentDiary, patient, discountPercent) {
      const userData = getUserData()
      if (userData) {
        await store.dispatch('info/getIntegrator', userData.integrator_id).then(response => {
          const integrator = response.data
          if (!treatmentDiary || !patient || !integrator) {
            showCommonToast(
              'Ошибка формирования акта сдачи-приемки оказанных медицинских услуг',
              'Пожалуйста, обратитесь в службу технической поддержки',
              'danger'
            )
            return false
          }
          let pdfFont = 'Times-Roman'
          const pdfFontBold = 'bold'
          const pdfFontNormal = 'normal'
          const pdfMargin = 8
          const pdfFontSize = 12
          const date = moment(parseInt(treatmentDiary.date)).format('L')
          let pdf = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})
          const scaleFactor = pdf.getLineHeightFactor()
          const pdfWidth = pdf.internal.pageSize.width

          // header
          pdf = mixins.methods.getPdfDocumentHeader(pdf, integrator, pdfFont, pdfMargin)
          let positionY = mixins.methods.getPositionAfterHeader(integrator, 44, false)

          let text = `Акт сдачи-приемки оказанных медицинских услуг от ${date} г.`
          pdf.setFont(pdfFont, pdfFontNormal)
          pdf.setFontSize(pdfFontSize)
          pdf.text(text, pdfWidth / 2, positionY, 'center')

          positionY += (pdf.getTextDimensions(text).h + scaleFactor) * 3
          const patientInfo = [patient.full_name]
          if (patient.address) {
            patientInfo.push(patient.address)
          }
          text = `${integrator.name}, в соответствии с Публичным договором на оказание платных медицинских услуг,
были оказаны медицинские услуги пациенту ${patientInfo.join(', ')}:`
          positionY = textToLines(
            text, null, pdf, pdfMargin, positionY, pdfFont, true, scaleFactor, false
          )
          pdf.setLineHeightFactor(1)

          const tablesHeader = [
            {content: 'Номер', styles: {halign: 'center'}},
            {content: 'Наименование', styles: {halign: 'center'}},
            {content: 'Количество', styles: {halign: 'center'}},
            {content: 'Цена', styles: {halign: 'center'}},
            {content: 'Сумма', styles: {halign: 'center'}}
          ]
          let rows = [tablesHeader]

          treatmentDiary.dental_services_history.forEach(s => {
            rows.push([
              {content: s.number, styles: {halign: 'center', valign: 'middle'}},
              {content: s.name, styles: {valign: 'middle'}},
              {content: s.count, styles: {halign: 'center', valign: 'middle'}},
              {content: mixins.methods.formatPrice(s.total_price, true), styles: {halign: 'center'}},
              {
                content: mixins.methods.formatPrice(s.total_price * s.count, true),
                styles: {halign: 'center'}
              }
            ])
          })
          let totalPrice = treatmentDiary.dental_services_history.reduce(
            (totalPrice, s) =>
              totalPrice + parseFloat(mixins.methods.formatPrice(s.total_price * s.count, false)), 0
          )
          totalPrice = parseFloat(mixins.methods.formatPrice(totalPrice))
          const discountAmount = treatmentDiary.dental_services_history.length
            ? getPaymentDiscountAmountByTreatmentDiary(treatmentDiary, discountPercent)
            : (discountPercent / 100) * totalPrice
          let salePrice = discountPercent ? (totalPrice - discountAmount) : totalPrice
          salePrice = salePrice < 0 ? 0 : salePrice

          rows.push([
            {content: 'Итого', styles: {halign: 'left', valign: 'middle'}, colSpan: 4},
            {content: mixins.methods.formatPrice(totalPrice, true), styles: { halign: 'center' }}
          ])

          pdf.autoTable({
            theme: 'grid',
            startY: positionY,
            margin: {top: 0, right: pdfMargin, bottom: 0, left: pdfMargin},
            body: rows,
            bodyStyles: {
              cellPadding: 1,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0]
            },
            styles: {
              font: 'OpenSans-Regular',
              fontSize: 9,
            },
            didParseCell: hook => {
              if (hook.row.index === 0) {
                hook.cell.styles.font = 'OpenSans-Bold'
              }
            },
            didDrawPage: hook => {
              positionY = (hook.cursor.y + pdf.getTextDimensions('T').h + scaleFactor)
            }
          })

          text = `Врач, оказавший услуги: ${treatmentDiary.doctor ? treatmentDiary.doctor.full_name : ''}`
          positionY += pdf.getTextDimensions(text).h + scaleFactor
          pdf.text(text, pdfMargin, positionY)
          positionY += 1
          pdf.line(
            pdfMargin, positionY,
            treatmentDiary.doctor ? pdfMargin + pdfMixins.methods.getTextWidth(pdf, text) : pdfWidth / 2, positionY
          )

          positionY += (pdf.getTextDimensions(text).h + scaleFactor) * 2
          text = 'Я, '
          pdf.text(text, pdfMargin, positionY)
          pdf.text(patient.full_name, pdfMargin + pdfMixins.methods.getTextWidth(pdf, text), positionY)
          let sumText = text + patient.full_name
          pdf.line(
            pdfMargin + pdfMixins.methods.getTextWidth(pdf, text),
            positionY + 1,
            pdfMargin + pdfMixins.methods.getTextWidth(pdf, sumText),
            positionY + 1
          )
          text = ', № карты'
          pdf.text(text, pdfMargin + pdfMixins.methods.getTextWidth(pdf, sumText), positionY)
          sumText = sumText + text + ' '
          const cardNumber = patient.patient_card_number ? patient.patient_card_number.toString() : ''
          pdf.text(
            cardNumber,
            pdfMargin + pdfMixins.methods.getTextWidth(pdf, sumText)
            + ((20 - pdfMixins.methods.getTextWidth(pdf, cardNumber)) / 2),
            positionY
          )
          pdf.line(
            pdfMargin + pdfMixins.methods.getTextWidth(pdf, sumText),
            positionY + 1,
            pdfMargin + pdfMixins.methods.getTextWidth(pdf, sumText) + 20,
            positionY + 1
          )
          text = ', подписывая настоящий акт подтверждаю,'
          pdf.text(text, pdfMargin + pdfMixins.methods.getTextWidth(pdf, sumText) + 20, positionY)

          positionY += pdf.getTextDimensions(text).h + scaleFactor
          text = 'что до получения медицинской услуги был (а) ознакомлен (а)  с Публичным договором, Правилами' +
            ' внутреннего распорядка для пациентов, мне до получения услуги в доступной и понятной форме был разъяснен' +
            ' порядок ее оказания: в том числе о противопоказаниях и рекомендациях в реабилитационный период и' +
            ' последствиях их невыполнения, а также  возможных осложнениях. '
          positionY = textToLines(
            text, null, pdf, pdfMargin, positionY, pdfFont, true, scaleFactor, false
          )

          text = 'Услуга оказана в полном объеме, претензий не имею.'
          pdf.text(text, pdfMargin, positionY)

          positionY += (pdf.getTextDimensions(text).h + scaleFactor) * 2
          pdf.setFont(pdfFont, pdfFontBold)
          pdf.text('ИТОГО', pdfMargin, positionY)
          pdf.setFont(pdfFont, pdfFontNormal)
          positionY += (pdf.getTextDimensions(text).h + scaleFactor) * 2

          pdf.setFont(pdfFont, pdfFontBold)
          let totalText = 'Начислено:   '
          pdf.text(totalText, pdfMargin, positionY)
          pdf.setFont(pdfFont, pdfFontNormal)
          pdf.text(
            mixins.methods.formatPrice(totalPrice, true),
            pdfMargin + pdf.getTextWidth(totalText),
            positionY
          )

          pdf.setFont(pdfFont, pdfFontBold)
          let discountText = 'Скидка %:   '
          pdf.text(discountText, (pdfWidth / 4) + 4, positionY)
          pdf.setFont(pdfFont, pdfFontNormal)
          pdf.text(
            discountPercent ? discountPercent.toString() : '0',
            (pdfWidth / 4) + 4 + pdf.getTextWidth(discountText),
            positionY
          )

          pdf.setFont(pdfFont, pdfFontBold)
          let payText = 'К оплате:   '
          pdf.text(payText, pdfWidth / 2, positionY)
          pdf.setFont(pdfFont, pdfFontNormal)
          pdf.text(
            (salePrice > 0 && totalPrice) || discountPercent === 100
              ? mixins.methods.formatPrice(salePrice, true)
              : mixins.methods.formatPrice(totalPrice, true),
            (pdfWidth / 2) + pdf.getTextWidth(payText),
            positionY
          )
          positionY += (pdf.getTextDimensions(text).h + scaleFactor) * 2

          text = 'Исполнитель'
          pdf.text(text, pdfMargin, positionY)
          pdf.line(
            pdfMargin + pdfMixins.methods.getTextWidth(pdf, text) + 10,
            positionY + 1,
            pdfWidth / 2 - 10,
            positionY + 1
          )
          pdf.text(text, pdfMargin, positionY)
          pdf.text(
            '(подпись)', pdfMargin + pdfMixins.methods.getTextWidth(pdf, text) + 27,
            positionY + pdf.getTextDimensions(text).h + scaleFactor
          )

          text = actPatientLabel()
          pdf.text(text, pdfWidth / 2, positionY)
          pdf.line(
            pdfWidth / 2 + pdfMixins.methods.getTextWidth(pdf, text) + 10,
            positionY + 1,
            pdfWidth - pdfMargin - 20,
            positionY + 1
          )
          pdf.text(
            '(подпись)', pdfWidth / 2 + pdfMixins.methods.getTextWidth(pdf, text) + 27,
            positionY + pdf.getTextDimensions(text).h + scaleFactor
          )

          pdf.save('Акт сдачи-приемки оказанных медицинских услуг - ' + patient.full_name + ` (${date}).pdf`)
        })
      }
    },
    async createActOfWorkPerformedWithRequisites(treatmentDiaryId) {
      let treatmentDiary = null
      await store.dispatch('patient/getTreatmentDiaryInfo', treatmentDiaryId)
        .then(({ data }) => treatmentDiary = data)
      if (!treatmentDiary) {
        showFailedOperationToast(
          'Ошибка формирования акта выполненных работ с реквизитами. Пожалуйста, обратитесь в службу технической' +
          ' поддержки'
        )
        return
      }
      const patient = treatmentDiary.patient
      const integrator = treatmentDiary.patient.integrator
      const insurance = treatmentDiary.insurance
      const priceCalcSystem = mixins.methods.getMaterialPriceCalcSystem()
      let pdfFont = 'Times-Roman'
      const pdfFontNormal = 'normal'
      const pdfFontBold = 'bold'
      const pdfMargin = 8
      const pdfFontSize = 12
      const date = moment(parseInt(treatmentDiary.date)).format('LL')
      let pdf = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})
      const scaleFactor = pdf.getLineHeightFactor()
      const pdfWidth = pdf.internal.pageSize.width
      const pdfHeight = pdf.internal.pageSize.height
      let positionY = 10

      pdf.setFont(pdfFont, pdfFontNormal)
      pdf.setFontSize(pdfFontSize)
      let textForMargin = 'Дата оказания услуги:'
      let textForMarginWidth = pdfMixins.methods.getTextWidth(pdf, textForMargin)
      let offset = pdfMargin + textForMarginWidth + 1
      let text = `АКТ ВЫПОЛНЕННЫХ РАБОТ от ${date}`
      pdf.setFont(pdfFont, pdfFontBold)
      pdf.text(text, pdfWidth / 2, positionY, 'center')
      positionY += (pdf.getTextDimensions(text).h + scaleFactor)

      text = 'к договору б/н от'
      pdf.text(text, pdfWidth / 2, positionY, 'center')
      positionY += (pdf.getTextDimensions(text).h + scaleFactor) * 2

      pdf.setFont(pdfFont, pdfFontNormal)
      text = 'Ф.И.О. пациента'
      pdf.text(text, pdfMargin, positionY)
      pdf.setFont(pdfFont, pdfFontBold)
      const docInfo = getPatientDocumentInfo(patient)
      text = `${patient.full_name + (docInfo ? ` (${docInfo.join(' ')})` : '')}`
      positionY = textToLines(
        text, null, pdf, offset, positionY, pdfFont, true, scaleFactor, false
      )
      positionY += pdf.getTextDimensions(text).h + scaleFactor

      if (insurance && insurance.policy_number) {
        text = 'Страховой полис:'
        pdf.setFont(pdfFont, pdfFontNormal)
        pdf.text(text, pdfWidth / 2 + 10, positionY)
        const textWidth = pdfMixins.methods.getTextWidth(pdf, text)
        pdf.setFont(pdfFont, pdfFontBold)
        positionY = textToLines(
          insurance.policy_number, null, pdf, pdfWidth / 2 + 11 + textWidth, positionY, pdfFont,
          true, scaleFactor, false, pdfMargin
        )
      }
      positionY += pdf.getTextDimensions(text).h + scaleFactor

      pdf.setFont(pdfFont, pdfFontNormal)
      pdf.text(textForMargin, pdfMargin, positionY)
      pdf.setFont(pdfFont, pdfFontBold)
      pdf.text(date, offset, positionY)
      positionY += pdf.getTextDimensions(text).h + scaleFactor

      text = 'Лечащий врач:'
      pdf.setFont(pdfFont, pdfFontNormal)
      pdf.text(text, pdfMargin, positionY)
      pdf.setFont(pdfFont, pdfFontBold)
      pdf.text(treatmentDiary.doctor ? treatmentDiary.doctor.full_name_initials : '', offset, positionY)
      positionY += pdf.getTextDimensions(text).h + scaleFactor

      pdf.setFont(pdfFont, pdfFontNormal)
      text = 'Диагноз:'
      pdf.text(text, pdfMargin, positionY)
      pdf.setFont(pdfFont, pdfFontBold)
      text = treatmentDiary.diagnoses.length
        ? treatmentDiary.diagnoses.map(d => `${d.full_name + (d.mkb ? ` (${d.mkb})` : '')}`).join(', ') : ''
      if (text) {
        positionY = textToLines(
          text, null, pdf, offset, positionY, pdfFont, true, scaleFactor, false
        )
      } else {
        positionY += pdf.getTextDimensions('Text').h + scaleFactor
      }
      pdf.setLineHeightFactor(1)
      const tablesHeader = [
        {content: 'Код', styles: {halign: 'center', valign: 'middle'}},
        {content: 'Вид стоматологической помощи', styles: {halign: 'center', valign: 'middle'}},
        {content: 'Цена услуги с материалами, руб.', styles: {halign: 'center', valign: 'middle'}},
        {content: 'Количество', styles: {halign: 'center', valign: 'middle'}},
        {content: 'Стоимость, руб.', styles: {halign: 'center', valign: 'middle'}}
      ]
      let rows = [tablesHeader]

      const materials = {}
      let totalPrice = 0
      treatmentDiary.dental_services_history.forEach(s => {
        totalPrice += parseFloat(mixins.methods.formatPrice(s.total_price * s.count, false))
        rows.push([
          {content: s.number, styles: {halign: 'center', valign: 'middle'}},
          {content: s.name.toLowerCase(), styles: {valign: 'middle'}},
          {
            content: mixins.methods.formatPrice(s.total_price, false).replace('.', ','),
            styles: {halign: 'right', valign: 'middle'}
          },
          {
            content: parseFloat(s.count).toFixed(2).replace('.', ','),
            styles: {halign: 'right', valign: 'middle'}
          },
          {
            content: mixins.methods.formatPrice(s.total_price * s.count, false).replace('.', ','),
            styles: {halign: 'right', valign: 'middle'}
          }
        ])
        s.materials.map(m => {
          if (materials[m.id]) {
            materials[m.id].count += s.count * m.part_rate
          } else {
            materials[m.id] = {
              name: m.material_name,
              sku: m.sku,
              count: s.count * m.part_rate,
              price: m.price,
              price_per_unit: m.price_per_unit,
              price_per_unit_without_nds: m.price_per_unit_without_nds,
              nds: m.nds_value,
              unitType: m.unit_type ? m.unit_type.short_name : null,
            }
          }
        })
      })
      totalPrice = parseFloat(mixins.methods.formatPrice(totalPrice))

      let totalMaterialNdsPrice = 0
      let totalMaterialWithNds10Price = 0
      let totalMaterialWithNds20Price = 0
      let totalMaterialsPriceWithNds = 0
      if (Object.keys(materials).length) {
        Object.keys(materials).map(key => {
          const material = materials[key]
          const nds = material.nds ? material.nds : null
          const pricePerUnit = material.price
          const totalPrice = pricePerUnit * material.count
          let ndsPrice
          if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
            ndsPrice = (material.price_per_unit - material.price_per_unit_without_nds) * material.count
          } else {
            ndsPrice = nds ? (totalPrice / 100) * nds : null
          }
          const totalPriceWithNds = ndsPrice ? totalPrice + ndsPrice : totalPrice
          if (nds === 10) {
            totalMaterialWithNds10Price += totalPriceWithNds
            totalMaterialNdsPrice += ndsPrice
          }
          if (nds === 20) {
            totalMaterialWithNds20Price += totalPriceWithNds
            totalMaterialNdsPrice += ndsPrice
          }
          totalMaterialsPriceWithNds += totalPriceWithNds
        })
      }
      rows.push([
        {content: 'ИТОГО СТОИМОСТЬ МЕДИЦИНСКИХ УСЛУГ без НДС (округл.)', styles: {halign: 'right'}, colSpan: 4},
        {
          content: mixins.methods.formatPrice(
            totalPrice - totalMaterialsPriceWithNds, false
          ).replace('.', ','),
          styles: { halign: 'right' }
        }
      ])
      rows.push([
        {content: 'ИТОГО СТОИМОСТЬ ИСПОЛЬЗОВАННЫХ МАТЕРИАЛОВ', styles: {halign: 'right'}, colSpan: 4},
        {
          content: mixins.methods.formatPrice(totalMaterialsPriceWithNds, false).replace('.', ','),
          styles: { halign: 'right' }
        }
      ])
      rows.push([
        {content: 'в том числе материалы, облагаемые НДС по ставке 10% с НДС', styles: {halign: 'right'}, colSpan: 4},
        {
          content: mixins.methods.formatPrice(totalMaterialWithNds10Price, false).replace('.', ','),
          styles: { halign: 'right' }
        }
      ])
      rows.push([
        {content: 'в том числе материалы, облагаемые НДС по ставке 20% с НДС', styles: {halign: 'right'}, colSpan: 4},
        {
          content: mixins.methods.formatPrice(totalMaterialWithNds20Price, false).replace('.', ','),
          styles: { halign: 'right' }
        }
      ])
      rows.push([
        {content: 'ВСЕГО К ОПЛАТЕ', styles: {halign: 'right', valign: 'middle'}, colSpan: 4},
        {
          content: mixins.methods.formatPrice(totalPrice, false).replace('.', ','),
          styles: { halign: 'right', valign: 'middle' }
        }
      ])
      const servicesCount = treatmentDiary.dental_services_history.length

      pdf.autoTable({
        theme: 'grid',
        startY: positionY,
        margin: {top: 0, right: pdfMargin, bottom: 10, left: pdfMargin},
        body: rows,
        bodyStyles: {
          cellPadding: 1,
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0]
        },
        styles: {
          font: pdfFont,
          fontSize: 9,
        },
        didParseCell: hook => {
          if (hook.row.index >= servicesCount + 2 && hook.row.index <= servicesCount + 4) {
            if (hook.row.index === servicesCount + 2) {
              hook.cell.styles.lineWidth = {top: 0.1, right: 0.1, bottom: 0, left: 0.1}
            } else if (hook.row.index === servicesCount + 4) {
              hook.cell.styles.lineWidth = {top: 0, right: 0.1, bottom: 0.1, left: 0.1}
            } else {
              hook.cell.styles.lineWidth = {top: 0, right: 0.1, bottom: 0, left: 0.1}
            }
          }
          if (hook.row.index === servicesCount + 5) {
            hook.cell.styles.fontSize = 10
            hook.cell.styles.fontStyle = pdfFontBold
            if (hook.column.index === 4) {
              hook.cell.styles.fillColor = '#D3D3D3'
            }
          }
        },
        didDrawPage: hook => {
          positionY = hook.cursor.y + pdf.getTextDimensions('T').h + scaleFactor
          hook.settings.margin = {top: 10, right: pdfMargin, bottom: 10, left: pdfMargin}
        }
      })
      positionY += pdf.getTextDimensions('Text').h + scaleFactor
      text = 'Всего к оплате: ' + mixins.methods.capitalizeFirstLetter(
        mixins.methods.priceToText(totalPrice.toFixed(2))
      ) + (
        totalMaterialNdsPrice
          ? ', в том числе НДС - ' + mixins.methods.capitalizeFirstLetter(
            mixins.methods.priceToText(totalMaterialNdsPrice.toFixed(2))
          )
          : ''
      )
      pdf.setFont(pdfFont, pdfFontBold)
      positionY = textToLines(
        text, null, pdf, pdfMargin, positionY, pdfFont, true, scaleFactor, false
      )

      text = 'Стороны к качеству оказанных услуг претензий не имеют.'
      pdf.setFont(pdfFont, pdfFontNormal)
      if (pdfHeight + 10 + pdf.getTextDimensions(text).h + scaleFactor < positionY) {
        pdf.addPage()
        positionY = 10
      } else {
        positionY += pdf.getTextDimensions(text).h + scaleFactor
      }
      pdf.text(text, pdfMargin, positionY)
      positionY += (pdf.getTextDimensions(text).h + scaleFactor) * 2

      let oldPositionY = positionY
      if (insurance && insurance.insurance_company) {
        text = 'Заказчик:'
        pdf.text(text, pdfMargin, positionY)
        positionY += pdf.getTextDimensions(text).h + scaleFactor
        pdf.text(insurance.insurance_company.name, pdfMargin, positionY)
        positionY += pdf.getTextDimensions(text).h + scaleFactor
        if (insurance.insurance_company.address) {
          pdf.text(insurance.insurance_company.address, pdfMargin, positionY)
          positionY += pdf.getTextDimensions(text).h + scaleFactor
        }
        if (insurance.insurance_company.phone) {
          pdf.text(`тел. ${insurance.insurance_company.phone}`, pdfMargin, positionY)
          positionY += pdf.getTextDimensions(text).h + scaleFactor
        }
        const bankInfo = []
        if (insurance.insurance_company.current_account) {
          bankInfo.push(`р/с ${insurance.insurance_company.current_account}`)
        }
        if (insurance.insurance_company.bank) {
          bankInfo.push(`в ${insurance.insurance_company.bank}`)
        }
        if (insurance.insurance_company.unp) {
          bankInfo.push(`УНП ${insurance.insurance_company.unp}`)
        }
        if (insurance.insurance_company.okpo) {
          bankInfo.push(`ОКПО ${insurance.insurance_company.okpo}`)
        }
        positionY = textToLines(
          bankInfo.join(', '), null, pdf, pdfMargin, positionY, pdfFont, true, scaleFactor,
          false, pdfWidth / 2
        )
        positionY += pdf.getTextDimensions(text).h + scaleFactor
        pdf.line(pdfMargin, positionY + 1, pdfMargin + 40, positionY + 1)
        positionY += pdf.getTextDimensions(text).h + scaleFactor
        pdf.text('М.П.', pdfMargin, positionY)
      }

      text = 'Исполнитель:'
      positionY = oldPositionY
      const startPosition = (pdfWidth / 2) + 10
      pdf.text(text, startPosition, positionY)
      positionY += pdf.getTextDimensions(text).h + scaleFactor
      pdf.text(integrator.name, startPosition, positionY)
      positionY += pdf.getTextDimensions(text).h + scaleFactor
      if (integrator.address) {
        pdf.text(integrator.address, startPosition, positionY)
        positionY += pdf.getTextDimensions(text).h + scaleFactor
      }
      if (integrator.phone) {
        pdf.text(`тел. ${integrator.phone}`, startPosition, positionY)
        positionY += pdf.getTextDimensions(text).h + scaleFactor
      }
      if (integrator.current_account) {
        pdf.text(
          `р/с ${integrator.current_account + (integrator.bank ? ' в' : '')}`,
          startPosition, positionY
        )
        positionY += pdf.getTextDimensions(text).h + scaleFactor
      }
      if (integrator.bank) {
        positionY = textToLines(
          integrator.bank, null, pdf, startPosition, positionY, pdfFont, true,
          scaleFactor, false, pdfMargin
        )
      }
      if (integrator.bic) {
        pdf.text(`код БИК: ${integrator.bic}`, startPosition, positionY)
        positionY += pdf.getTextDimensions(text).h + scaleFactor
      }
      if (integrator.license_number) {
        pdf.text(`лиц. ${integrator.license_number}`, startPosition, positionY)
        positionY += pdf.getTextDimensions(text).h + scaleFactor
      }

      positionY += pdf.getTextDimensions(text).h + scaleFactor
      text = integrator.signer_position ? integrator.signer_position : 'Директор'
      pdf.text(text, startPosition, positionY)
      pdf.line(
        startPosition + pdfMixins.methods.getTextWidth(pdf, text) + 1,
        positionY + 1,
        startPosition + pdfMixins.methods.getTextWidth(pdf, text) + 31,
        positionY + 1
      )
      pdf.text(
        fullNameToInitials(integrator.signer_full_name),
        startPosition + pdfMixins.methods.getTextWidth(pdf, text) + 32,
        positionY
      )
      positionY += pdf.getTextDimensions(text).h + scaleFactor
      pdf.text('М.П.', startPosition, positionY)

      pdf.save('Акт выполненных работ - ' + patient.full_name + ` (${date}).pdf`)
    },
    setActTitle(pdfFile, integrator, treatmentDiary, pdfWidth, pdfCurrentPositionY) {
      pdfFile.text(this.getActTitle(treatmentDiary), pdfWidth / 2, pdfCurrentPositionY, 'center')
    },
    getActTitle(treatmentDiary) {
      let title = this.getActNameFormat() === 'invoice-for-payment-of-services'
        ? 'Счет на оплату услуг' : 'Акт оказанных услуг'
      if (this.isActNumberBNFormatEnabledInDocument()) {
        title = title + ' № б/н'
      } else if (treatmentDiary.serial_number) {
        title = title + ' №' + treatmentDiary.serial_number
      }
      return title
    },
    getActContractNumber(treatmentDiary, patient) {
      let number = mixins.methods.getActContractNumberFormat() === 'patient-card-number'
        ? patient.patient_card_number : treatmentDiary.act_number
      return number ? number.toString() : ''
    },
    connectToUserSocket() {
      const user = this.getUser()
      if (user && user.id) {
        let ws = new WebSocket(process.env.VUE_APP_WS_HOST + '/ws/user/' + user.id)
        ws.onopen = () => {
          console.log('client connected to user socket')
          store.commit('auth/SET_USER_SOCKET_CONNECTION', ws)
        }

        ws.onmessage = async e => {
          const data = e.data ? JSON.parse(e.data) : null
          await mixins.methods.processUserNotification(data)
        }

        ws.onclose = e=> {
          if (e.code) {
            if (e.code === 1000) {
              console.log('User socket is closed. Client logged out.')
            } else {
              console.log('User socket is closed. Reconnect will be attempted in 10 seconds.', e.reason)
              setTimeout(() => mixins.methods.connectToUserSocket(), 10000)
            }
            store.commit('auth/CLEAR_USER_SOCKET_CONNECTION')
          }
        }

        ws.onerror = e=> {
          console.error('Socket encountered error: ', e.message, 'Closing socket')
          store.commit('auth/CLEAR_USER_SOCKET_CONNECTION')
        }

        setTimeout(() => {
          if (ws.readyState !== 1) {
            console.log('close socket by timeout')
            ws.close()
          }
        }, 1000)
      }
    },
    async processUserNotification(data) {
      const message = data && data.message ? data.message : null
      const actionType = data && data.msg_action_type ? data.msg_action_type : null
      if (message && actionType) {
        const chatActions = ['new-chat-message', 'update-chat-message', 'remove-chat-message']
        if (actionType === 'system-notification') {
          systemNotify(message)
        } else if (['success-operation-notification', 'failed-operation-notification'].includes(actionType)) {
          if (actionType === 'success-operation-notification') {
            showSuccessOperationToast(message)
          } else {
            showFailedOperationToast(message)
          }
          bus.$emit('processed-operation-notification')
        } else if (actionType === 'minimum-sms-notification-balance') {
          minimumSmsNotificationBalanceNotify(message)
        } else if (actionType === 'subscription-expired-notification') {
          subscriptionExpiredNotify(message)
        } else if (actionType === 'online-appointment') {
          showOnlineAppointmentToast(message)
        } else if (actionType === 'new-chat-message') {
          processNewChatMessageByRoute(data)
        } else if (actionType === 'patient-processed-appointment-notification') {
          patientProcessedAppointmentNotification(data)
        } else if (actionType === 'incoming-patient-call') {
          processIncomingPatientCallNotif(data)
        } else if (actionType === 'new-lead') {
          newLeadNotify(data)
        } else if (actionType === 'user-operation-notification') {
          await processUserOperationNotification(message)
        } else if (!chatActions.includes(actionType)) {
          systemNotify({id: null, text: message})
        }
      }
    },
    connectToIntegratorSocket() {
      if (!store.getters['auth/getIntegratorSocketConnection']) {
        const user = this.getUser()
        if (user && user.id) {
          let ws = new WebSocket(process.env.VUE_APP_WS_HOST + '/ws/integrator/' + user.integrator_id)
          ws.onopen = () => {
            console.log('client connected to integrator socket')
            store.commit('auth/SET_INTEGRATOR_SOCKET_CONNECTION', ws)
          }

          ws.onmessage = e=> {
            const data = e.data ? JSON.parse(e.data) : null
            const message = data && data.message ? data.message : null
            const messageActionType = data && data.msg_action_type ? data.msg_action_type : null
            if (messageActionType === 'patient-appointment-changes' && user.id !== message.creator_id) {
              store.commit('schedule/SET_NEW_PATIENT_APPOINTMENT_INFO', message)
            }
          }

          ws.onclose = e => {
            if (e.code) {
              if (e.code === 1000) {
                console.log('Integrator socket is closed. Client logged out.')
              } else {
                console.log('Integrator socket is closed. Reconnect will be attempted in 10 seconds.', e.reason)
                setTimeout(() => mixins.methods.connectToIntegratorSocket(), 10000)
              }
              store.commit('auth/CLEAR_INTEGRATOR_SOCKET_CONNECTION')
            }
          }

          ws.onerror = e=> {
            console.error('Socket encountered error: ', e.message, 'Closing socket')
            store.commit('auth/CLEAR_INTEGRATOR_SOCKET_CONNECTION')
          }

          setTimeout(() => {
            if (ws.readyState !== 1) {
              console.log('close socket by timeout')
              ws.close()
            }
          }, 1000)
        }
      }
    },
    getUser() {
      const localeStorageUser = localStorage.getItem('user')
      return localeStorageUser ? JSON.parse(localeStorageUser) : null
    },
    getUserTimezone() {
      const user = this.getUser()
      return user ? user.timezone : null
    },
    getDocumentSettings() {
      const user = this.getUser()
      return user && user.document_settings ? user.document_settings : null
    },
    isDiagnosisHiddenInDocument() {
      const settings = this.getDocumentSettings()
      return settings && settings.diagnosis_hidden
    },
    isWarrantyHiddenInDocument() {
      const settings = this.getDocumentSettings()
      return settings && settings.warranty_hidden
    },
    showNdsInfoInDocument() {
      const settings = this.getDocumentSettings()
      return settings && settings.show_nds_info
    },
    getSignerLabelForAct() {
      const settings = this.getDocumentSettings()
      const label = settings && settings.act_signer_label ? settings.act_signer_label : 'Кассир'
      return `${mixins.methods.capitalizeFirstLetter(label)}: `
    },
    isActNumberBNFormatEnabledInDocument() {
      const settings = this.getDocumentSettings()
      return settings && settings.act_number_bn_format_enabled
    },
    getActNameFormat() {
      const settings = this.getDocumentSettings()
      return settings && settings.act_name_format ? settings.act_name_format : null
    },
    getActContractNumberFormat() {
      const settings = this.getDocumentSettings()
      return settings && settings.act_contract_number_format ? settings.act_contract_number_format : null
    },
    isCredentialsVisibleInLastVisit() {
      const settings = this.getDocumentSettings()
      return settings ? settings.show_credentials : true
    },
    showEmptyFieldInLastVisiting() {
      const settings = this.getDocumentSettings()
      return settings ? settings.last_visit_show_empty_field : true
    },
    getPriceSettings() {
      const user = this.getUser()
      return user && user.price_settings ? user.price_settings : null
    },
    getNumberOfDecimalPlaces() {
      const settings = this.getPriceSettings()
      return settings && (settings.number_of_decimal_places || settings.number_of_decimal_places === 0)
        ? parseInt(settings.number_of_decimal_places) : null
    },
    isRoundingDownEnabled() {
      const settings = this.getPriceSettings()
      return settings && settings.rounding_down_enabled
    },
    isAutoRecalculationWholesaleMarkupEnabled() {
      const settings = this.getPriceSettings()
      return settings && settings.auto_recalculation_wholesale_markup_enabled
    },
    getBaseValue() {
      const settings = this.getPriceSettings()
      return settings && settings.base_value ? parseFloat(settings.base_value) : 0
    },
    getDecreasingCoefficient() {
      const settings = this.getPriceSettings()
      return settings ? settings.decreasing_coefficient : null
    },
    isProhibitZeroMaterialConsumption() {
      const settings = this.getPriceSettings()
      return settings && !!settings.prohibit_zero_material_consumption
    },
    integratorHasFixedServicePrice() {
      const settings = this.getPriceSettings()
      return !!(settings && settings.fixed_service_price)
    },
    getMaterialPriceCalcSystem() {
      const settings = this.getPriceSettings()
      return settings && settings.material_price_calc_system
        ? settings.material_price_calc_system : appConstants.material.materialPriceCalcSystem.OSN
    },
    getTreatmentDiarySettings() {
      const user = this.getUser()
      return user && user.treatment_diary_settings ? user.treatment_diary_settings : null
    },
    getPatientCardSettings() {
      const user = this.getUser()
      return user && user.patient_card_settings ? user.patient_card_settings : null
    },
    isProhibitPaymentsEditOlderCurrentDayEnabled() {
      const settings = this.getCashRegisterSettings()
      return settings && settings.prohibit_payments_edit_older_current_day
    },
    canUsePromoPaymentLogic() {
      const settings = this.getCashRegisterSettings()
      return settings ? settings.use_promo_payment_logic : true
    },
    getCashRegisterSettings() {
      const user = this.getUser()
      return user && user.cash_register_settings ? user.cash_register_settings : null
    },
    getHiddenCashRegisterSections() {
      const settings = this.getCashRegisterSettings()
      return settings && settings.hidden_sections ? settings.hidden_sections : []
    },
    isComplexFilterByDiagnosisEnabled() {
      const settings = this.getTreatmentDiarySettings()
      return settings && settings.complex_filter_by_diagnosis_enabled
    },
    medicineDiaryUseVisitType() {
      const settings = this.getTreatmentDiarySettings()
      return settings && settings.medicine_diary_use_visit_type
    },
    availableTreatmentDiaryDocumentTypes() {
      const user = this.getUser()
      return user && user.treatment_diary_documents && user.treatment_diary_documents.length
        ? user.treatment_diary_documents : []
    },
    patientPhoneIsRequired() {
      const settings = this.getPatientCardSettings()
      return settings && settings.phone_is_required
    },
    tabDentistryIsHidden() {
      const settings = this.getPatientCardSettings()
      return !!settings && settings.hide_tab_dentistry
    },
    workPlaceIsHidden() {
      const settings = this.getPatientCardSettings()
      return settings && settings.hide_work_place
    },
    socialStatusIsHidden() {
      const settings = this.getPatientCardSettings()
      return settings && settings.hide_social_status
    },
    automaticCardNumerationDisabled() {
      const settings = this.getPatientCardSettings()
      return settings && settings.disable_automatic_card_numeration
    },
    getEmployeeSettings() {
      const user = this.getUser()
      return user && user.employee_settings ? user.employee_settings : null
    },
    hidePatientPhoneNumberForDoctor() {
      const user = this.getUser()
      const settings = this.getEmployeeSettings()
      return user && (user.role_type === 'doctor') && settings && settings.hide_patient_phone_number_for_doctor
    },
    getDentalServiceSettings() {
      const user = this.getUser()
      return user && user.dental_service_settings ? user.dental_service_settings : null
    },
    useSynevoServices() {
      const settings = this.getDentalServiceSettings()
      return settings && settings.use_synevo_services
    },
    getSynevoApiSettings() {
      const settings = this.getDentalServiceSettings()
      return settings && settings.synevo_api ? settings.synevo_api : null
    },
    getPatientReceptionScheduleSettings() {
      const user = this.getUser()
      return user && user.patient_reception_schedule_settings ? user.patient_reception_schedule_settings : null
    },
    showPatientDentalCardState() {
      const settings = this.getPatientReceptionScheduleSettings()
      return settings && settings.show_patient_dental_card_state
    },
    simplifiedRecordDisplayFormatEnabled() {
      const settings = this.getPatientReceptionScheduleSettings()
      return settings && settings.simplified_record_display_format
    },
    pastTimePatientsRecording() {
      const settings = this.getPatientReceptionScheduleSettings()
      return settings && settings.past_time_patients_recording
    },
    isBranchLogicEnabled() {
      const user = this.getUser()
      return user && !!user.branch_logic_enabled
    },
    isATEEnabled() {
      const user = this.getUser()
      return user && !!user.ate_enabled
    },
    userHasIntegratorBranches() {
      const user = this.getUser()
      return user && !!user.has_integrator_branches
    },
    formatText(text) {
      return text.trim().replaceAll("\r", ' ').replaceAll("\n", ' ')
        .replaceAll("\t", ' ')
    },
    firstLetterIsUppercase: (str) => typeof str !== 'string' || str.length === 0
      ? false : (str[0].toUpperCase() === str[0]),
    addUserIntegratorBranchesToStorage(data) {
      this.addUserDataToStorageByPrefix('user-calendar-integrator-branch', data)
    },
    addUserDirectionToStorage(data) {
      this.addUserDataToStorageByPrefix('user-calendar-direction', data)
    },
    addUserDoctorsToStorage(data) {
      this.addUserDataToStorageByPrefix('user-calendar-doctors', data)
    },
    addUserCalendarSettingsToStorage(data) {
      this.addUserDataToStorageByPrefix('user-calendar-settings', data)
    },
    addUserMaterialPriceListSettingsToStorage(data) {
      this.addUserDataToStorageByPrefix('user-material-price-list-settings', data)
    },
    addUserCalendarFormDataToStorage(data) {
      this.addUserDataToStorageByPrefix('user-calendar-form-data', data)
    },
    addUserServiceTimeModalSettingsToStorage(data) {
      this.addUserDataToStorageByPrefix('user-service-time-modal-settings', data)
    },
    removeCalendarSettingsInStorage() {
      this.removeItemInStorageByPrefix('user-calendar-settings')
    },
    addUserDataToStorageByPrefix(prefix, data) {
      const userData = getUserData()
      if (userData && userData.id && data) {
        localStorage.setItem(userData.id + '-' + prefix, JSON.stringify(data))
      }
    },
    getUserIntegratorBranchesFromStorage() {
      return this.getUserDataFromStorageByPrefix('user-calendar-integrator-branch')
    },
    getUserDirectionsFromStorage() {
      return this.getUserDataFromStorageByPrefix('user-calendar-direction')
    },
    getUserDoctorsFromStorage() {
      return this.getUserDataFromStorageByPrefix('user-calendar-doctors')
    },
    getUserCalendarSettingsFromStorage() {
      return this.getUserDataFromStorageByPrefix('user-calendar-settings')
    },
    getUserMaterialPriceListSettingsFromStorage() {
      return this.getUserDataFromStorageByPrefix('user-material-price-list-settings')
    },
    getUserCalendarFormDataFromStorage() {
      return this.getUserDataFromStorageByPrefix('user-calendar-form-data')
    },
    getUserServiceTimeModalSettingsFromStorage() {
      return this.getUserDataFromStorageByPrefix('user-service-time-modal-settings')
    },
    getUserDataFromStorageByPrefix(prefix) {
      const userData = getUserData()
      if (userData && userData.id) {
        let item = localStorage.getItem(userData.id + '-' + prefix)
        item = item ? JSON.parse(item) : null
        return item ? item : null
      }
      return null
    },
    removeItemInStorageByPrefix(prefix) {
      const userData = getUserData()
      if (userData && userData.id) {
        localStorage.removeItem(userData.id + '-' + prefix)
      }
    },
    userHasProfmanDentalProgramVersion() {
      const userData = getUserData()
      return userData && (userData.program_version === 'profman_dental')
    },
    getUserRoleType() {
      const user = this.getUser()
      return user ? user.role_type : ''
    },
    getPatientContactViewPasswordSendingMethod() {
      const user = this.getUser()
      return user ? user.patient_contact_view_password_sending_method : ''
    },
    positiveNumberFormatter(value) {
      if (value) {
        value = value < 0 ? Math.abs(value).toString() : value
        if (value.charAt(0) === '0' && value.charAt(1) !== '.' && value.length > 1) {
          value = value.slice(1)
        }
      }
      return value
    },
    ceilPositiveNumberFormatter(value) {
      return Math.ceil(this.positiveNumberFormatter(value))
    },
    capitalizeFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : string
    },
    amountToHumanReadableAmount(amount) {
      if (amount) {
        const ceilPart = Math.trunc(amount)
        const ceilPartLength = ceilPart.toString().length
        const fractionalPart = parseInt((amount % 1).toFixed(2).toString().split('.')[1])
        const fractionalPartLength = fractionalPart.toString().length
        const keysCeilPart = {
          '0_5+': 'белорусских рублей',
          '1': 'белорусский рубль',
          '2_4': 'белорусских рубля',
        }
        const keysFractionalPart = {
          '0_5+': 'копеек',
          '1': 'копейка',
          '2_4': 'копейки',
        }
        const result = [this.amountPartToHumanReadableAmount(ceilPart, ceilPartLength, keysCeilPart)]
        const humanReadableFractionalPartAmount = this.amountPartToHumanReadableAmount(
          fractionalPart, fractionalPartLength, keysFractionalPart
        )
        if (humanReadableFractionalPartAmount) {
          result.push(humanReadableFractionalPartAmount)
        }
        return result.join(' ')
      }
      return '0 белорусских рублей'
    },
    amountPartToHumanReadableAmount(number, numberLength, dict) {
      let result = ''
      if (numberLength === 1) {
        if (![1, 2, 3, 4].includes(number)) {
          result = `${number} ${dict['0_5+']}`
        } else if (number === 1) {
          result = `${number} ${dict['1']}`
        } else {
          result = `${number} ${dict['2_4']}`
        }
      } else {
        const lastTwoNumbers = number.toString().substring(numberLength - 2)
        const lastTwoNumbersAsInt = parseInt(lastTwoNumbers)
        if ([0, 5, 6, 7, 8, 9].includes(number) || (lastTwoNumbersAsInt >= 10 && lastTwoNumbersAsInt <= 20)) {
          result = `${number} ${dict['0_5+']}`
        } else if (lastTwoNumbersAsInt > 20) {
          const lastDigit = parseInt(lastTwoNumbers[1])
          if (![1, 2, 3, 4].includes(lastDigit)) {
            result = `${number} ${dict['0_5+']}`
          } else if (lastDigit === 1) {
            result = `${number} ${dict['1']}`
          } else {
            result = `${number} ${dict['2_4']}`
          }
        } else if (lastTwoNumbersAsInt === 1) {
          result = `${number} ${dict['1']}`
        } else {
          result = `${number} ${dict['2_4']}`
        }
      }
      return result
    },
    currentUserIsRoot() {
      const user = getUserData()
      return user && user.isRoot
    },
    getMaterialPriceFromLots(material, partRate) {
      const lots = material.lots
      partRate = parseFloat(partRate)
      let totalMaterialExistingNumber = 0
      let prevTotalMaterialExistingNumber = 0
      let price = 0
      let count = 0
      for (let i = 0; i < lots.length; i++) {
        prevTotalMaterialExistingNumber = totalMaterialExistingNumber
        totalMaterialExistingNumber += material.partial_consumption_of_material_unit
          ? lots[i].total_number_of_material_unit_type : lots[i].number_of_material
        if (totalMaterialExistingNumber >= partRate) {
          if (i === 0) {
            if (material.partial_consumption_of_material_unit) {
              let number = lots[i].number_of_material_unit_type_per_material_unit
              number = number ? number : 1
              price += lots[i].price_per_unit ? (lots[i].price_per_unit / number) * partRate : 0
            } else {
              price += lots[i].price_per_unit ? lots[i].price_per_unit * partRate : 0
            }
          } else {
            count = partRate - prevTotalMaterialExistingNumber
            if (material.partial_consumption_of_material_unit) {
              let number = lots[i].number_of_material_unit_type_per_material_unit
              number = number ? number : 1
              price += lots[i].price_per_unit ? (lots[i].price_per_unit / number) * count : 0
            } else {
              price += lots[i].price_per_unit ? lots[i].price_per_unit * count : 0
            }
          }
          break;
        } else {
          if (i === lots.length - 1) {
            count = partRate - prevTotalMaterialExistingNumber
          } else {
            count = material.partial_consumption_of_material_unit
              ? lots[i].total_number_of_material_unit_type : lots[i].number_of_material
          }
          if (material.partial_consumption_of_material_unit) {
            let number = lots[i].number_of_material_unit_type_per_material_unit
            number = number ? number : 1
            price += lots[i].price_per_unit ? (lots[i].price_per_unit / number) * count : 0
          } else {
            price += lots[i].price_per_unit ? lots[i].price_per_unit * count : 0
          }
        }
      }
      return price
    },
    getMaterialLotInfo(lot, partial_consumption_of_material_unit, part_rate) {
      const data = {
        id: lot.id,
        price: 0,
        price_per_unit: 0,
        count: part_rate,
        arrival_date: lot.arrival_date,
        delivery_order_number: lot.delivery_order_number,
        nds: lot.nds ? lot.nds.value : null,
        price_per_unit_without_nds: 0,
        price_without_nds: 0,
        number_of_material: lot.number_of_material,
        material_expended_number: lot.material_expended_number
      }
      if (partial_consumption_of_material_unit) {
        const numberOfMaterialUnitTypePerMaterialUnit = lot.number_of_material_unit_type_per_material_unit
          ? lot.number_of_material_unit_type_per_material_unit : 1
        data.price_per_unit = lot.price_per_unit ? lot.price_per_unit / numberOfMaterialUnitTypePerMaterialUnit : 0
        data.price_per_unit_without_nds = lot.price_per_unit_without_nds
          ? lot.price_per_unit_without_nds / numberOfMaterialUnitTypePerMaterialUnit : data.price_per_unit
      } else {
        data.price_per_unit = lot.price_per_unit ? lot.price_per_unit : 0
        data.price_per_unit_without_nds = lot.price_per_unit_without_nds
          ? lot.price_per_unit_without_nds : data.price_per_unit
      }
      data.price = lot.price_per_unit * part_rate
      data.price_without_nds = lot.price_per_unit_without_nds * part_rate
      return data
    },
    priceToText(price) {
      const { format } = require('@vicimpa/rubles')
      let text = format(price)
      const position = text.indexOf('руб')
      return text.substring(0, position) + 'белорусских ' + text.substring(position, text.length)
    },
    getTradeMarginPercent(type, coefficient) {
      const TYPE_MEDICAL_DEVICES = 'medical-devices'
      const TYPE_MEDICAL_EQUIPMENT = 'medical-equipment'
      const TYPE_MEDICINAL_PRODUCTS = 'medicinal-products'
      let percent = 0
      switch (type) {
        case TYPE_MEDICINAL_PRODUCTS:
          if (coefficient <= 0.5) {
            percent = 30
          } else if (coefficient > 0.5 && coefficient <= 1) {
            percent = 25
          } else if (coefficient > 1 && coefficient <= 1.5) {
            percent = 14
          } else if (coefficient > 1.5 && coefficient <= 3) {
            percent = 12
          } else if (coefficient > 3 && coefficient <= 5) {
            percent = 10
          } else if (coefficient > 5 && coefficient <= 10) {
            percent = 5
          } else if (coefficient > 10) {
            percent = 1
          }
          break;
        case TYPE_MEDICAL_DEVICES:
          if (coefficient <= 0.5) {
            percent = 30
          } else if (coefficient > 0.5 && coefficient <= 1) {
            percent = 25
          } else if (coefficient > 1 && coefficient <= 1.5) {
            percent = 21
          } else if (coefficient > 1.5 && coefficient <= 5) {
            percent = 17
          } else if (coefficient > 5) {
            percent = 6
          }
          break;
        case TYPE_MEDICAL_EQUIPMENT:
          if (coefficient <= 1000) {
            percent = 20
          } else if (coefficient > 1000 && coefficient <= 5000) {
            percent = 0
          } else if (coefficient > 5000 && coefficient <= 50000) {
            percent = 0
          } else if (coefficient > 50000 && coefficient <= 100000) {
            percent = 0
          } else if (coefficient > 100000) {
            percent = 0
          }
          break;
      }
      return percent
    },
    getWholesaleMarkupPercent(type, coefficient) {
      const TYPE_MEDICAL_DEVICES = 'medical-devices'
      const TYPE_MEDICAL_EQUIPMENT = 'medical-equipment'
      const TYPE_MEDICINAL_PRODUCTS = 'medicinal-products'
      let percent = 0
      switch (type) {
        case TYPE_MEDICINAL_PRODUCTS:
          if (coefficient <= 0.5) {
            percent = 9
          } else if (coefficient > 0.5 && coefficient <= 1) {
            percent = 8
          } else if (coefficient > 1 && coefficient <= 1.5) {
            percent = 7
          } else if (coefficient > 1.5 && coefficient <= 3) {
            percent = 7
          } else if (coefficient > 3 && coefficient <= 5) {
            percent = 6
          } else if (coefficient > 5 && coefficient <= 10) {
            percent = 4
          } else if (coefficient > 10) {
            percent = 2
          }
          break;
        case TYPE_MEDICAL_DEVICES:
          if (coefficient <= 0.5) {
            percent = 11
          } else if (coefficient > 0.5 && coefficient <= 1) {
            percent = 8
          } else if (coefficient > 1 && coefficient <= 1.5) {
            percent = 8
          } else if (coefficient > 1.5 && coefficient <= 5) {
            percent = 8
          } else if (coefficient > 5) {
            percent = 4
          }
          break;
        case TYPE_MEDICAL_EQUIPMENT:
          if (coefficient <= 1000) {
            percent = 20
          } else if (coefficient > 1000 && coefficient <= 5000) {
            percent = 10
          } else if (coefficient > 5000 && coefficient <= 50000) {
            percent = 8
          } else if (coefficient > 50000 && coefficient <= 100000) {
            percent = 5
          } else if (coefficient > 100000) {
            percent = 3
          }
          break;
      }
      return percent
    }
  },
}
