import { mixins } from '@/mixins'
import { showWarningOperationEternalToast } from '@/composables/toast'

export const isNotEnoughMaterialByLot = (partRate, lot) => {
  if (mixins.methods.isProhibitZeroMaterialConsumption() && lot) {
    partRate = partRate ? parseFloat(partRate) : 0
    const initNumberOfMaterial = lot.number_of_material ? lot.number_of_material : 0
    const materialExpendedNumber = lot.material_expended_number ? lot.material_expended_number : 0
    return (initNumberOfMaterial - materialExpendedNumber - partRate) < 0
  }
  return false
}

export const isNotEnoughMaterial = (partRate, numberOfMaterial, materialExpendedNumber) => {
  if (mixins.methods.isProhibitZeroMaterialConsumption()) {
    partRate = partRate ? parseFloat(partRate) : 0
    numberOfMaterial = numberOfMaterial ? numberOfMaterial : 0
    materialExpendedNumber = materialExpendedNumber ? materialExpendedNumber : 0
    return (numberOfMaterial - materialExpendedNumber - partRate) < 0
  }
  return false
}

export const countOfMaterialsExistByServiceCount = (serviceCount, materials) => {
  if (mixins.methods.isProhibitZeroMaterialConsumption()) {
    return !(materials.findIndex(m => !countOfMaterialExistByServiceCount(serviceCount, m)) > -1)
  }
  return true
}

export const countOfMaterialExistByServiceCount = (serviceCount, m) => {
  const mCount = m.system_column_count ? m.system_column_count : m.part_rate
  const partRate = parseFloat((mCount * serviceCount).toFixed(5))
  let numberOfMaterial = m.lot_info.number_of_material ? m.lot_info.number_of_material : 0
  let materialExpendedNumber = m.lot_info.material_expended_number
    ? m.lot_info.material_expended_number : 0
  if (m.from_history && m.total_material_expended_number) {
    materialExpendedNumber -= m.total_material_expended_number
  }
  if (isNotEnoughMaterial(partRate, numberOfMaterial, materialExpendedNumber)) {
    let diff = numberOfMaterial - materialExpendedNumber
    diff = diff < 0 ? 0 : parseFloat(diff.toFixed(5))
    const message = `Материал "${m.material_name}" доступен в количестве ${diff}. Вы запрашиваете ${partRate}. 
      Уберите из услуги недостоющий материал, обнулите его количество, либо пополните его на складе.`
    showWarningOperationEternalToast(message, 'Недостаточно матеарила!')
    return false
  }
  return true
}
